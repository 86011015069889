import React from "react";
import { Radio } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledRadio = styled(Radio)({
  color: "gray",
  "&.Mui-checked": {
    color: "white",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "18px",
  },
});

const RadioButton = ({ selectedValue, setSelectedValue }) => {
  return (
    <StyledRadio
      checked={selectedValue}
      onChange={() => {
        setSelectedValue(selectedValue)
      }}
    />
  );
};

export default RadioButton;
