import React, { useState, useEffect } from "react";
import { Fab, useScrollTrigger, Zoom } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const ScrollTop = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100, // Show button when scrolled 100px down the page
  });

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Zoom in={trigger}>
      <div
        onClick={handleClick}
        role="presentation"
        style={{ position: "fixed", bottom: 16, right: 16 }}
      >
        <Fab
          color="secondary"
          size="large"
          aria-label="scroll back to top"
          sx={{
            backgroundColor: "orange",
          }}
        >
          <KeyboardArrowUpIcon
            sx={{
              color: "#orange",
            }}
          />
        </Fab>
      </div>
    </Zoom>
  );
};

export default ScrollTop;
