import React from "react";
import { Box } from "@mui/material";

import mainLogo from "../assets/main-logo.png"; // Path to your logo file

const Logo = ({ width = "150px", height = "auto" }) => {
  return (
    <Box
      component="img"
      src={mainLogo}
      alt="Logo"
      sx={{
        width: width,
        height: height,
      }}
    />
  );
};

export default Logo;
