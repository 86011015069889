import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Person2Icon from "@mui/icons-material/Person2";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import PolylineIcon from "@mui/icons-material/Polyline";
import HistoryIcon from "@mui/icons-material/History";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import { Avatar } from "@mui/material";
import ProfileSection from "../../pages/profile-tab-sections/profile-section";
import BalanceSection from "../../pages/profile-tab-sections/balance-section";
import { useState, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InvestSection from "../../pages/profile-tab-sections/invest-section";
import MyWaysSection from "../../pages/profile-tab-sections/my-ways-section";
import HistorySection from "../../pages/profile-tab-sections/history-section";
import TeamSection from "../../pages/profile-tab-sections/team-section";
import HelpSection from "../../pages/profile-tab-sections/help-section";
import useUploadImage from "../../utils/upload-image";
import { useProfile } from "../../context/use-profile";

function TabPanel({ children, value, index, ...other }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: isMobile ? "100%" : "auto" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const StyledTabPanel = styled(TabPanel)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledTab = styled(Tab)({
  fontWeight: "bold",
  textTransform: "capitalize",
  padding: "10px 20px",
  borderRadius: "0 10px 10px 0",
  color: "#eee",
  "&.Mui-selected": {
    background: "black",
    color: "white",
  },
  minHeight: 0,
  justifyContent: "flex-start",
  "& svg": {
    marginRight: "15px !important",
  },
});
const StyledTabs = styled(Tabs)({
  fontWeight: "bold",
  borderRight: 1,
  borderColor: "divider",
  padding: "0 20px 20px 0",
});

const TabsList = ({ value, handleChange }) => {
  const { t } = useTranslation();

  return (
    <StyledTabs
      orientation="vertical"
      variant="scrollable"
      value={value}
      onChange={handleChange}
      indicatorColor="none"
    >
      <StyledTab
        iconPosition="start"
        icon={<Person2Icon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.profile")}
      />
      <StyledTab
        iconPosition="start"
        icon={<AccountBalanceWalletIcon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.balance")}
      />
      <StyledTab
        iconPosition="start"
        icon={<CreditScoreIcon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.invest")}
      />
      <StyledTab
        iconPosition="start"
        icon={<PolylineIcon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.directions")}
      />
      <StyledTab
        iconPosition="start"
        icon={<HistoryIcon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.history")}
      />
      <StyledTab
        iconPosition="start"
        icon={<Diversity3Icon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.team")}
      />
      <StyledTab
        iconPosition="start"
        icon={<SupportAgentIcon sx={{ width: 20, height: 20 }} />}
        label={t("profile.menu.help")}
      />
    </StyledTabs>
  );
};

const ProfileAvatarHeader = ({
  image = "",
  userName = "",
  uploadImage,
  title = "",
}) => {
  const fileInputRef = useRef(null);
  const handleAvatarClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const body = await uploadImage(file);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <button
        onClick={handleAvatarClick}
        style={{
          display: "flex",
          justifyItems: "center",
          alignItems: "center",
          width: "56px",
          height: "56px",
          borderRadius: "50%",
        }}
      >
        {image !== "default" ? (
          <Avatar
            alt="Avatar"
            src={image}
            variant="rounded"
            sx={{ borderRadius: "50%" }}
          />
        ) : (
          <AccountCircleIcon
            sx={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        )}
      </button>
      <input
        ref={fileInputRef}
        name="upload-photo"
        accept="image/*"
        type="file"
        hidden
        onChange={handleFileChange}
      />

      <Box sx={{ marginLeft: "10px" }}>
        <Typography sx={{ fontWeight: "bold" }} variant="subtitle2">
          {title}
        </Typography>
        <Typography sx={{ fontWeight: "bold" }} variant="subtitle1">
          {userName || ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default function ProfileMenu() {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { userProfile, updateProfile, paymentList } = useProfile();
  const { handleUpload } = useUploadImage();
  const { t } = useTranslation();

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab) {
      const tabIndex = {
        balance: 1,
        invest: 2,
        myWays: 3,
        history: 4,
        team: 5,
        help: 6,
      }[tab];

      if (tabIndex !== undefined) {
        setValue(tabIndex);
      } else {
        setValue(0);
      }
    }
  }, [searchParams]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === 0) {
      navigate("/profile");
    } else {
      const tabParam = [
        "profile",
        "balance",
        "invest",
        "myWays",
        "history",
        "team",
        "help",
      ][newValue];
      setSearchParams({ tab: tabParam });
    }
  };

  const _handleUpload = async (image) => {
    const body = await handleUpload(image, userProfile.uid);
    updateProfile(body);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        color: "white",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        width: "100%",
        height: "auto",
        padding: { xs: "10px", md: "0" },
        gap: { xs: "10px", md: 0 },
      }}
    >
      <Box
        sx={{
          background:
            "linear-gradient(90deg, rgba(47,47,47,1) 0%, rgba(147,145,145,1) 100%)",
          borderRadius: { xs: "20px", md: "0 20px 20px 0" },
          marginRight: { xs: "0", md: "20px" },
          height: "fit-content",
        }}
      >
        <ProfileAvatarHeader
          userName={userProfile?.name}
          image={userProfile?.avatar}
          uploadImage={_handleUpload}
          title={t("profile.menu.title")}
        />
        <TabsList value={value} handleChange={handleChange} />
      </Box>
      <Box
        sx={{
          flex: 1,
          paddingRight: { xs: "0", md: "20px" },
        }}
      >
        <StyledTabPanel value={value} index={0}>
          <ProfileSection userProfile={userProfile} />
        </StyledTabPanel>
        <StyledTabPanel value={value} index={1}>
          <BalanceSection
            userProfile={userProfile}
            transactions={userProfile?.transactions || []}
            paymentList={paymentList}
          />
        </StyledTabPanel>
        <StyledTabPanel value={value} index={2}>
          <InvestSection userProfile={userProfile} t={t} />
        </StyledTabPanel>
        <StyledTabPanel value={value} index={3}>
          <MyWaysSection t={t} investment={userProfile?.investment || []} />
        </StyledTabPanel>
        <StyledTabPanel value={value} index={4}>
          <HistorySection transactions={userProfile?.transactions || []} />
        </StyledTabPanel>
        <StyledTabPanel value={value} index={5}>
          <TeamSection
            userId={userProfile?.uid}
            referralList={userProfile?.referralList || []}
            refIncome={userProfile?.totalRefIncome || 0}
            refBonus={userProfile?.totalRefBonus || 0}
          />
        </StyledTabPanel>
        <StyledTabPanel value={value} index={6}>
          <HelpSection />
        </StyledTabPanel>
      </Box>
    </Box>
  );
}
