import { Fragment } from "react";
import Box from "@mui/material/Box";

import { Typography } from "@mui/material";
import MainButton from "../../components/MainButton";

import instrumentsImage1 from "../../assets/instruments-1.jpg";
import instrumentsImage2 from "../../assets/instruments-2.jpg";
import instrumentsImage3 from "../../assets/instruments-3.jpg";

const InstrumentItem = ({ image, text, actionText, onClick }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        backgroundPosition: "center",
        width: "300px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          height: "400px",
          width: "100%",
          margin: "0 10px",
        }}
      >
        <Box sx={{ ...overlayStyles, position: "relative", height: "100px" }}>
          <Typography
            variant="body1"
            color={"#fff"}
            sx={{
              ...boxTextStyles,
              fontSize: "16px",
              color: "#fff",
              position: "relative",
              zIndex: 2,
              padding: "10px",
              textAlign: "center",
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <MainButton text={actionText} onClick={onClick} />
      </Box>
    </Box>
  );
};

const sectionStyles = {
  margin: "100px 0",
};

const overlayStyles = {
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1,
  },
};

const titleStyle = {
  fontWeight: "bolder",
  marginBottom: "20px",
  borderBottom: "3px solid yellow",
  marginRight: { xs: 0, md: "auto" },
  fontSize: { xs: "24px", md: "40px" },
  textAlign: { xs: "center", md: "start" },
  paddingBottom: "10px",
};

const boxTextStyles = { fontWeight: 600, textAlign: "center" };

const Section2 = ({ t, navigate }) => {
  return (
    <Fragment>
      <Box>
        <Box sx={sectionStyles}>
          <Box
            sx={{
              width: "100%",
              marginTop: "50px",
              zIndex: 2,
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                maxWidth: "800px",
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "0px 0",
                paddingBottom: "40px",
              }}
            >
              <Box>
                <Typography sx={titleStyle}>{t("home.intro")}</Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  color={"#fff"}
                  sx={{ fontWeight: 600, textAlign: "left", width: "100%" }}
                >
                  {t("home.company_activities")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                maxWidth: "800px",
                flexDirection: "column",
                alignItems: "flex-end",
                margin: "0 0 0 auto",
                paddingBottom: "40px",
              }}
            >
              <Box>
                <Typography sx={titleStyle}>{t("home.goal")}</Typography>
              </Box>
              <Box>
                <Typography
                  variant="subtitle1"
                  color={"#fff"}
                  sx={{
                    fontWeight: 600,
                    textAlign: "right",
                    marginLeft: "auto",
                  }}
                >
                  {t("home.goal_2")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={sectionStyles}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bolder",
              textAlign: "center",
              margin: "50px auto 0 auto",
              maxWidth: "800px",
            }}
            gutterBottom
          >
            {t("home.financial_tools")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              gap: "20px",
            }}
          >
            <InstrumentItem
              text={t("home.financial_tool_item_1")}
              image={instrumentsImage1}
              actionText={t("home.button.details")}
              onClick={() => navigate("/directions?tool=1")}
            />
            <InstrumentItem
              text={t("home.financial_tool_item_2")}
              image={instrumentsImage2}
              actionText={t("home.button.details")}
              onClick={() => navigate("/directions?tool=3")}
            />
            <InstrumentItem
              text={t("home.financial_tool_item_3")}
              image={instrumentsImage3}
              actionText={t("home.button.details")}
              onClick={() => navigate("/directions?tool=2")}
            />
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Section2;
