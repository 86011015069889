import React, { memo } from "react";
import { NavLink } from "react-router-dom";

const RouterLink = ({ children, routePath }) => {
  return (
    <NavLink
      to={routePath}
      style={({ isActive }) => ({
        color: isActive ? "yellow" : "white",
        textDecoration: "none",
        padding: "0 10px",
        fontSize: "15px",
        fontWeight: 600,
      })}
    >
      {children}
    </NavLink>
  );
};

const MemoIzedRouterLink = memo(RouterLink);

export default RouterLink;
