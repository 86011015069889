import React, { useCallback, useMemo, useState } from "react";
import { Modal, Box, Typography, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles"
import MainLogo from "./MainLogo";
import MainButton from "./MainButton";

const steps = [
  {
    title: "education.modal.title1",
    description: {
      part1: {
        core: "education.modal.description.part1.core1",
        text: "education.modal.description.part1.text1",
      },
      part2: {
        core: "education.modal.description.part1.core2",
        text: "education.modal.description.part1.text2",
      },
    },
  },
  {
    title: "education.modal.title2",
    description: {
      part1: {
        core: "education.modal.description.part2.core1",
        text: "education.modal.description.part2.text1",
      },
      part2: {
        core: "education.modal.description.part2.core2",
        text: "education.modal.description.part2.text2",
      },
    },
  },
  {
    title: "education.modal.title3",
    description: {
      part1: {
        core: "education.modal.description.part3.core1",
        text: "education.modal.description.part3.text1",
      },
      part2: {
        core: "education.modal.description.part3.core2",
        text: "education.modal.description.part3.text2",
      },
    },
  },
  {
    title: "education.modal.title4",
    description: {
      part1: {
        core: "education.modal.description.part4.core1",
        text: "education.modal.description.part4.text1",
      },
      part2: {
        core: "education.modal.description.part4.core2",
        text: "education.modal.description.part4.text2",
      },
    },
  },
];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "linear-gradient(to right, #0e1111, grey)",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
  padding: "20px 30px",
  border: "1px solid #fff",
};

function EducationModal({ setOpen = () => {}, open = true }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  };

  const handleNext = useCallback(() => {
    setActiveStep((prev) => {
      if (prev === steps.length - 1) {
        return prev;
      }

      return prev + 1;
    });
  }, []);

  // const progress = (activeStep / (steps(t).length - 1)) * 100;

  const progress = useMemo(() => {
    return (activeStep / (steps.length - 1)) * 100;
  }, [activeStep]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ ...modalStyle, width: isMobile ? '95%' : '600px'}}>
        <MainLogo />
        <Typography
          sx={{
            color: "#fff",
            fontSize: "26px",
            fontWeight: 600,
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {t(steps[activeStep].title)}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            my: 2,
            bgcolor: "white",
            "& .MuiLinearProgress-bar": { bgcolor: "yellow" },
            borderRadius: "10px",
          }}
        />
        <Box>
          <Typography
            sx={{
              color: "yellow",
              fontSize: "16px",
              fontWeight: 600,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {t(steps[activeStep].description.part1.core)}:{" "}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "10px",
              display: "flex",
            }}
          >
            {t(steps[activeStep].description.part1.text)}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              color: "yellow",
              fontSize: "16px",
              fontWeight: 600,
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            {t(steps[activeStep].description.part2.core)}:{" "}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "10px",
              display: "flex",
            }}
          >
            {t(steps[activeStep].description.part2.text)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            marginTop: "20px",
          }}
        >
          {activeStep === 3 ? (
            <MainButton onClick={handleClose} text={t("complete")} />
          ) : (
            <>
              <MainButton text={t("skip")} onClick={handleClose} />
              <MainButton text={t("next")} onClick={handleNext} disabled />
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default EducationModal;
