import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MainButton from "./MainButton";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { MenuItem, Select } from "@mui/material";

import HomePage from "../pages/home";
import ProfilePage from "../pages/profile";
import AboutUs from "../pages/about-us";
import Directions from "../pages/directions";
import Partners from "../pages/partners";

import { useAuth } from "../context/use-auth";
import MainLogo from "./MainLogo";
import FAQ from "../pages/faq";
import NavLink from "./NavLink";

import SignUpModal from "../components/SignUpModal";
import SignInModal from "./SignInModal";
import DrawerAppBar, { unAuthItems } from "./DrawerAppBar";

import { LANGUAGES, useLanguage } from "../context/use-language";

const StyledSelect = styled(Select)({
  outline: "none",
  backgroundColor: "transparent",
  border: "1px solid yellow",
  color: "white",
  borderRadius: "8px",
  flex: 1,

  "& .MuiSelect-select": {
    padding: "10px",
    fontWeight: "bold",
    fontSize: "15px",
  },

  "& label.Mui-focused, & .MuiSvgIcon-root": {
    color: "white",
  },

  "& fieldset": {
    border: "none",
  },
});

const UnAuthNavigation = ({
  onSignUp,
  onSignIn,
  openSignUpModal,
  openSignInModal,
  isOpenSignUpModal,
  isOpenSignInModal,
  handleDrawerToggle,
  onToggleSignUp,
  t,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { language, changeLanguage } = useLanguage();

  return (
    <>
      <Toolbar
        sx={{
          width: "100%",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <NavLink routePath="/">
            <MainLogo />
          </NavLink>
          <Box sx={{ display: isMobile ? "none" : "flex" }}>
            {unAuthItems.map((item) => {
              return (
                <NavLink key={item.name} routePath={`${item.routePath}`}>
                  {t(item.name)}
                </NavLink>
              );
            })}
          </Box>
        </Box>
        <Box
          sx={{
            display: isMobile ? "none" : "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            onChange={(e) => {
              changeLanguage(e.target.value);
            }}
          >
            {LANGUAGES.map((lang, idx) => (
              <MenuItem
                key={`${lang.code}-${idx + 1}}`}
                value={lang.code}
                sx={{ textTransform: "uppercase" }}
              >
                {lang.flag} {lang.name}
              </MenuItem>
            ))}
          </StyledSelect>
          <MainButton
            text={t("button.sign_in")}
            onClick={() => openSignInModal(true)}
          />
          <MainButton
            text={t("button.sign_up")}
            onClick={() => openSignUpModal(true)}
          />
        </Box>
      </Toolbar>
      <SignUpModal
        open={isOpenSignUpModal}
        handleClose={() => openSignUpModal(false)}
        onSignUp={onSignUp}
        t={t}
      />
      <SignInModal
        open={isOpenSignInModal}
        handleClose={() => openSignInModal(false)}
        onSignIn={onSignIn}
        onToggleSignUp={onToggleSignUp}
        t={t}
      />
    </>
  );
};

const AuthNavigation = ({ goProfile, handleDrawerToggle, logout, t }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { language, changeLanguage } = useLanguage();

  return (
    <>
      <Toolbar
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <NavLink routePath="/">
            <MainLogo />
          </NavLink>
        </Box>
        <Box
          sx={{
            display: isMobile ? "none" : "flex",
            flexDirection: "row",
            gap: "20px",
          }}
        >
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            onChange={(e) => {
              changeLanguage(e.target.value);
            }}
          >
            {LANGUAGES.map((lang, idx) => (
              <MenuItem
                key={`${lang.code}-${idx + 1}}`}
                value={lang.code}
                sx={{ textTransform: "uppercase" }}
              >
                {lang.flag} {lang.name}
              </MenuItem>
            ))}
          </StyledSelect>
          <MainButton text={t("profile")} onClick={goProfile} />
          <MainButton text={t("logout")} onClick={logout} />
        </Box>
      </Toolbar>
    </>
  );
};

const NavigationBar = (props) => {
  const [isOpenSignUpModal, openSignUpModal] = React.useState(false);
  const [isOpenSignInModal, openSignInModal] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { window } = props;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { currentUser, signUp, loading, logout, signIn } = useAuth();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const onSignUp = (data) => {
    signUp(data).then(() => {
      openSignUpModal(false);
    });
  };

  const goHome = () => {
    navigate("/");
  };

  const goProfile = () => {
    navigate("/profile");
  };

  const onToggleSignUp = () => {
    openSignInModal(false);
    setTimeout(() => {
      openSignUpModal(true);
    }, 500);
  };

  return (
    <>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "rgba(30,30,30, 0.5)",
          borderBottom: "1px solid #303030",
          backdropFilter: "blur(10px)",
          flex: "0 0 64px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        {currentUser
          ? AuthNavigation({
              goHome,
              goProfile,
              handleDrawerToggle,
              logout,
              t,
            })
          : UnAuthNavigation({
              onSignUp,
              onSignIn: signIn,
              openSignUpModal,
              openSignInModal,
              isOpenSignUpModal,
              isOpenSignInModal,
              handleDrawerToggle,
              onToggleSignUp,
              onToggleSignUp,
              t,
            })}
      </AppBar>
      <DrawerAppBar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        window={window}
        isAuth={currentUser}
        authActions={{
          goHome,
          goProfile,
          logout,
        }}
        unAuthActions={{
          openSignUpModal,
          openSignInModal,
          onToggleSignUp,
        }}
        t={t}
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/register" element={<HomePage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/directions" element={<Directions />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
    </>
  );
};

export default NavigationBar;
