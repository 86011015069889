import { Box, Typography, Button, Grid, Paper } from "@mui/material";

import { styled } from "@mui/material/styles";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../context/use-profile";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { arrayUnion, increment } from "firebase/firestore";

export const investArray = (t) => [
  {
    title: "Base",
    value: "base",
    period: t("base.duration"),
    image: "/invest2.png",
    sum: `${t("from")} 100$ - 10 000$`,
    earn: t("base.dailyInterest"),
    returnBody: t("body_in_end"),
    days: "daily",
    percent: 0.7,
    minValue: 100,
    formula: (value) => {
      return {
        daily: Math.round(value * 0.007),
        monthly: Math.round(value * 0.007 * 14),
      };
    },
    incomeFormula: (value) => {
      let startValue = 0;
      let incomeArray = [];

      for (let i = 0; i <= 14; i++) {
        startValue = startValue + Math.round(value * 0.007);

        if (i === 0) {
          incomeArray[i] = {
            date: moment().format(),
            value: 0,
          };
        } else {
          incomeArray[i] = {
            date: moment().add(i, "days").format(),
            value: startValue,
          };
        }
      }

      return incomeArray;
    },
  },
  {
    title: "Crypto",
    value: "crypto",
    days: 30,
    period: t("industry.duration"),
    image: "/invest2.png",
    sum: `${t("from")} 10 000$`,
    earn: t("crypto.dailyInterest"),
    returnBody: t("body_in_end"),
    percent: 1.1,
    minValue: 10000,
    formula: (value) => {
      return {
        daily: Math.round(value * 0.011),
        monthly: Math.round(value * 0.011 * 30),
      };
    },
    incomeFormula: (value) => {
      let startValue = 0;
      let incomeArray = [];

      for (let i = 0; i <= 30; i++) {
        startValue = startValue + Math.round(value * 0.011);
        if (i === 0) {
          incomeArray[i] = {
            date: moment().format(),
            value: 0,
          };
        } else {
          incomeArray[i] = {
            date: moment().add(i, "days").format(),
            value: startValue,
          };
        }
      }

      return incomeArray;
    },
  },
  {
    title: "Market",
    value: "market",
    // period: t("market.dailyInterest"),
    image: "/invest2.png",
    sum: t("market.investmentRange"),
    earn: t("market.dailyInterest"),
    returnBody: t("without_cashback"),
    percent: 1,
    days: "daily",
    minValue: 100,
    formula: (value) => {
      return {
        daily: Math.round(value * 0.01),
        monthly: null,
      };
    },
    incomeFormula: (value) => {
      let startValue = 0;
      let incomeArray = [];

      for (let i = 0; i <= 15; i++) {
        startValue = startValue + Math.round(value * 0.01);

        if (i === 0) {
          incomeArray[i] = {
            date: moment().format(),
            value: 0,
          };
        } else {
          incomeArray[i] = {
            date: moment().add(i, "days").format(),
            value: startValue,
          };
        }
      }

      return incomeArray;
    },
  },
  {
    title: "Starting",
    value: "starting",
    period: t("starting.duration"),
    image: "/invest1.png",
    sum: `${t("from")} 100$ - 10 000$`,
    earn: t("starting.dailyInterest"),
    returnBody: t("body_in_end"),
    days: 14,
    percent: 0.8,
    minValue: 100,
    formula: (value) => {
      return {
        daily: Math.round(value * 0.008),
        monthly: Math.round(value * 0.008 * 14),
      };
    },
    incomeFormula: (value) => {
      let startValue = 0;
      let incomeArray = [];

      for (let i = 0; i <= 14; i++) {
        startValue = startValue + Math.round(value * 0.008);

        if (i === 0) {
          incomeArray[i] = {
            date: moment().format(),
            value: 0,
          };
        } else {
          incomeArray[i] = {
            date: moment().add(i, "days").format(),
            value: startValue,
          };
        }
      }

      return incomeArray;
    },
  },
  {
    title: "Industry",
    value: "industry",
    period: t("industry.duration"),
    image: "/invest1.png",
    days: 30,
    percent: 1,
    sum: `${t("from")} 10 000$`,
    earn: t("industry.dailyInterest"),
    returnBody: t("body_in_end"),
    minValue: 10000,
    formula: (value) => {
      return {
        daily: Math.round(value * 0.01),
        monthly: Math.round(value * 0.01 * 30),
      };
    },
    incomeFormula: (value) => {
      let startValue = 0;
      let incomeArray = [];

      for (let i = 0; i <= 30; i++) {
        startValue = startValue + Math.round(value * 0.01);

        if (i === 0) {
          incomeArray[i] = {
            date: moment().format(),
            value: 0,
          };
        } else {
          incomeArray[i] = {
            date: moment().add(i, "days").format(),
            value: startValue,
          };
        }
      }

      return incomeArray;
    },
  },
  {
    title: "Global",
    value: "global",
    days: "daily",
    percent: 0.9,
    image: "/invest1.png",
    sum: `${t("from")} 100$`,
    earn: t("global.dailyInterest"),
    returnBody: t("market.principalReturn"),
    minValue: 100,
    formula: (value) => {
      return {
        daily: Math.round(value * 0.009),
        monthly: null,
      };
    },
    incomeFormula: (value) => {
      let startValue = 0;
      let incomeArray = [];

      for (let i = 0; i <= 15; i++) {
        startValue = startValue + Math.round(value * 0.009);

        if (i === 0) {
          incomeArray[i] = {
            date: moment().format(),
            value: 0,
          };
        } else {
          incomeArray[i] = {
            date: moment().add(i, "days").format(),
            value: startValue,
          };
        }
      }

      return incomeArray;
    },
  },
];

export const InvestTable = ({
  t,
  type = "profile", // profile | partners
  balance,
  calculateAmount = 0,
}) => {
  const navigate = useNavigate();
  const { updateProfile } = useProfile();

  const background = useMemo(() => {
    switch (type) {
      case "profile":
        return "linear-gradient(90deg, rgba(40,119,164,1) 28%, rgba(2,49,57,1) 100%)";
      case "partners":
        return "linear-gradient(90deg, rgba(255,173,0,1) 0%, rgba(255,78,86,1) 100%)";

      default:
    }
  }, [type]);

  const onNavigate = async (value) => {
    if (type === "partners") {
      return navigate("/directions");
    }

    const activeItem = investArray(t).filter((item) => item.value === value)[0];
    const calculatedIncome = activeItem.incomeFormula(+calculateAmount);
    const minValue = activeItem.minValue;

    if (+calculateAmount < minValue) {
      return NotificationManager.error(
        t("profile.invest.error", { minValue: `${minValue}$` }),
        t("notification.title.error")
      );
    }

    if (balance && balance >= +calculateAmount) {
      const activeItem = investArray(t).filter(
        (item) => item.value === value
      )[0];

      const investment = arrayUnion({
        investType: activeItem.value,
        amount: +calculateAmount,
        income: calculatedIncome,
        dateCreated: moment().format(),
        status: "in process",
      });

      const data = {
        hashNumber: "balance",
        dateCreated: moment().format(),
        status: "pending",
        action: "deposit",
        amount: +calculateAmount,
        receiverAccount: "balance",
        investType: activeItem.value,
      };

      await updateProfile({
        investment,
        balance: balance - Number(calculateAmount),
        transactions: arrayUnion(data),
        totalInvestment: increment(calculateAmount),
      })
        .then(() => {
          NotificationManager.success(
            t("notification.success"),
            t("notification.title.success")
          );
          navigate("/profile?tab=myWays")
        })
        .catch((err) => {
          NotificationManager.error(
            t("notification.error"),
            t("notification.title.error")
          );
        });
    } else {
      NotificationManager.error(
        t("invest.balance.error.title"),
        t("notification.title.error")
      );
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Grid
        container
        sx={{
          background: { xs: "transparent", md: "#eee" },
          backdropFilter: "blur(10px)",
          borderRadius: "20px 0 0 20px",
          padding: "15px",
          margin: "0 auto",
          flex: 1,
        }}
      >
        {investArray(t).map((investItem, idx) => (
          <Grid
            key={`${idx + 1}-item`}
            item
            xs={12}
            sm={6}
            md={4}
            sx={{ padding: "10px" }}
          >
            <Item>
              <ItemHeader sx={{ background }}>
                <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
                  {investItem.title}
                </Typography>
              </ItemHeader>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "5px 15px",
                  flex: 1,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                      {investItem.period || investItem.earn}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold", textAlign: "start" }}>
                      {investItem.period && investItem.earn}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", textAlign: "start" }}>
                      {investItem.returnBody}
                    </Typography>
                    <Typography sx={{ fontWeight: "bold", textAlign: "start" }}>
                      {investItem.sum}
                    </Typography>
                  </Box>
                </Box>
                <ItemButton
                  sx={{ background }}
                  onClick={() => onNavigate(investItem.value)}
                >
                  {t("partners.text_2")}
                </ItemButton>
              </Box>
            </Item>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          flex: "0 0 120px",
          borderRadius: "0 20px 20px 0",
          background,
        }}
      ></Box>
    </Box>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: "black",
  borderRadius: "15px",
  aspectRatio: "1 / 1",
  display: "flex",
  flexDirection: "column",
}));

const ItemHeader = styled(Box)({
  flex: "0 0 50px",
  padding: "8px",
  color: "#fff",
  fontWeight: "bolder",
  borderRadius: "15px 15px 0 0",
  textTransform: "uppercase",
});

const ItemButton = styled(Button)({
  borderRadius: "10px",
  padding: "8px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",
  margin: "0 auto",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});
