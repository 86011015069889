import {
  Box,
  Typography,
  TextField,
  Paper,
  Select,
  MenuItem,
  Container,
  Button,
} from "@mui/material";
import { useMemo, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { onInputNumber } from "../utils/validators";
import { investArray } from "../components/invest";

const initialValues = {
  daily: 0,
  monthly: 0,
};

export const Calculator = ({
  t,
  type = "profile", // profile | partners
  setCalculateAmount,
  calculateAmount,
}) => {
  const [calculateType, setCalculateType] = useState("base");
  const [calculation, setCalculation] = useState(initialValues);

  const calculateTypeObj = useMemo(() => {
    return investArray(t).find((item) => item.value === calculateType);
  }, [calculateType, t]);

  const calculate = useCallback(() => {
    let data = calculateAmount;

    if (!calculateAmount) {
      return data;
    }

    const activeItem = investArray(t).filter(
      (i) => i.value === calculateType
    )[0];

    data = activeItem.formula(+calculateAmount);

    setCalculation(data);
  }, [calculateAmount, calculateType, t]);

  const background = useMemo(() => {
    switch (type) {
      case "profile":
        return "linear-gradient(90deg, rgba(40,119,164,1) 28%, rgba(2,49,57,1) 100%)";
      case "partners":
        return "linear-gradient(90deg, rgba(255,173,0,1) 0%, rgba(255,78,86,1) 100%)";

      default:
    }
  }, [type]);

  const borderColor = useMemo(() => {
    switch (type) {
      case "profile":
        return "#07bb6a";
      case "partners":
        return "orange";

      default:
    }
  }, [type]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          gap: "10px",
          flex: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "white",
            marginBottom: "10px",
          }}
        >
          {t("partners.text_3")}
        </Typography>
        <Box sx={{ display: "flex", width: "100%" }}>
          <StyledLabel sx={{ borderColor }}>{t("sum")}:</StyledLabel>
          <StyledInput
            type="number"
            value={calculateAmount}
            onInput={(e) => {
              onInputNumber(e, setCalculateAmount);
            }}
          />
        </Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <StyledLabel sx={{ borderColor }}>{t("direction")}:</StyledLabel>
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={calculateType}
            onChange={(e) => {
              setCalculateType(e.target.value);
              setCalculation(initialValues);
              setCalculateAmount("");
            }}
          >
            {investArray(t).map((investType, idx) => (
              <MenuItem
                key={`${idx + 1}-MenuItem`}
                value={investType.value}
                sx={{ textTransform: "uppercase" }}
              >
                {investType.title}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
        <Box
          sx={{ display: "flex", width: "100%", justifyContent: "flex-end" }}
        >
          <StyledButton sx={{ background }} onClick={calculate}>
            {t("calculate")}
          </StyledButton>
        </Box>
      </Box>
      <Container
        sx={{
          flex: 1,
          paddingBottom: 0,
          paddingRight: "0 !important",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Item sx={{ aspectRatio: "auto" }}>
          <ItemHeader sx={{ background }}>
            <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
              {calculateTypeObj.earn}
            </Typography>
          </ItemHeader>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "5px 15px",
              flex: 1,
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {Boolean(calculation.daily) && (
                  <Typography sx={{ fontWeight: "bold", color: "green" }}>
                    {t("partners.text_4")}: {calculation.daily}$
                  </Typography>
                )}

                {Boolean(calculation.monthly) && (
                  <Typography sx={{ fontWeight: "bold", color: "green" }}>
                    {t("partners.text_5")}: {calculation.monthly}$
                  </Typography>
                )}
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("partners.text_6")}: {calculateTypeObj.period}
                </Typography>
                <Typography sx={{ fontWeight: "bold" }}>
                  {calculateTypeObj.sum}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Item>
      </Container>
    </Box>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: "black",
  borderRadius: "15px",
  aspectRatio: "1 / 1",
  display: "flex",
  flexDirection: "column",
}));

const ItemHeader = styled(Box)({
  flex: "0 0 50px",
  padding: "8px",
  color: "#fff",
  fontWeight: "bolder",
  borderRadius: "15px 15px 0 0",
  textTransform: "uppercase",
});

const StyledInput = styled(TextField)({
  outline: "none",
  backgroundColor: "transparent",
  border: "3px solid white",
  borderWidth: "3px 3px 3px 0",
  flex: 1,
  borderRadius: "0 8px 8px 0",

  "& label.Mui-focused": {
    color: "black",
  },
  "& label.MuiFormLabel-filled, & label.Mui-focused": {
    top: "0px",
    fontWeight: "bold",
  },
  "& label": {
    top: "-4px",
    fontWeight: "bold",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  "& input": {
    fontSize: "15px",
    fontWeight: "bold",
    color: "white",
    padding: "10px",
  },
  "& fieldset": {
    border: "none",
  },
});

const StyledSelect = styled(Select)({
  outline: "none",
  backgroundColor: "transparent",
  border: "3px solid white",
  borderWidth: "3px 3px 3px 0",
  color: "white",
  borderRadius: "0 8px 8px 0",
  flex: 1,

  "& .MuiSelect-select": {
    padding: "10px",
    fontWeight: "bold",
    fontSize: "15px",
  },

  "& label.Mui-focused, & .MuiSvgIcon-root": {
    color: "white",
  },

  "& fieldset": {
    border: "none",
  },
});

const StyledLabel = styled(Box)({
  border: "3px solid orange",
  borderWidth: "3px 0 3px 3px",
  borderRadius: "8px 0 0 8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 10px",
  fontWeight: "bold",
});

const StyledButton = styled(Button)({
  borderRadius: "10px",
  padding: "8px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});
