import { Fragment, useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import Filter3Icon from "@mui/icons-material/Filter3";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@mui/material";
import { t } from "i18next";
import { onFetchCryptoData } from "../../api/crypto";

const sectionStyles = {
  margin: "100px auto",
};

const Section3 = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [isColdStart, setColdStart] = useState(false);

  function createData(symbol, price, change24, v24, amount) {
    return { symbol, price, change24, v24, amount };
  }

  const rows = useMemo(() => {
    let rowList = [];

    if (cryptoData.length) {
      rowList = cryptoData.map((item) => {
        return createData(
          item.name,
          item.current_price,
          item.price_change_percentage_24h,
          item.total_volume,
          item.market_cap
        );
      });
    }

    return rowList;
  }, [cryptoData]);

  useEffect(() => {
    const inInitCoins = async () => {
      const data = await onFetchCryptoData();
      setCryptoData(data);
      setColdStart(true);
    };

    inInitCoins();
  }, []);

  useEffect(() => {
    if (!isColdStart) {
      return;
    }

    let timeout = setInterval(() => {
      const inInitCoins = async () => {
        const data = await onFetchCryptoData();
        setCryptoData(data);
      };

      inInitCoins();
    }, 60000);

    return () => {
      clearInterval(timeout);
    };
  }, [isColdStart]);

  return (
    <Fragment>
      <Box>
        <Box sx={sectionStyles}>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bolder",
              textAlign: "center",
              margin: "50px auto 0 auto",
              maxWidth: "800px",
            }}
            gutterBottom
          >
            {t("home.follow_title")}
          </Typography>

          <Box
            sx={{
              margin: "20px auto",
              backdropFilter: "blur(10px)",
              background: "rgba(255,255,255,0.1)",
              borderRadius: "15px",
              padding: "30px",
              maxWidth: 900,
            }}
          >
            <TableContainer component={Box}>
              <Table
                sx={{
                  width: "100%",
                }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {t("home.crypto_currency_label_1")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("home.crypto_currency_label_2")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("home.crypto_currency_label_3")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("home.crypto_currency_label_4")}
                    </StyledTableCell>
                    <StyledTableCell>
                      {t("home.crypto_currency_label_4")}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody sx={{ color: "white" }}>
                  {rows.map((row, idx) => (
                    <TableRow key={`item${row.name}${idx}`}>
                      <StyledTableCell>{row.symbol}</StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          sx={{
                            color: row.price > 0 ? "green" : "red",
                            fontSize: "18px",
                          }}
                        >
                          {row.price}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          sx={{
                            color: row.change24 > 0 ? "green" : "red",
                            fontSize: "18px",
                          }}
                        >
                          {row.change24}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          sx={{
                            color: row.v24 > 0 ? "green" : "red",
                            fontSize: "18px",
                          }}
                        >
                          {row.v24}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          sx={{
                            color: row.amount > 0 ? "green" : "red",
                            fontSize: "18px",
                          }}
                        >
                          {row.amount}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        <Box
          sx={{
            ...sectionStyles,
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            maxWidth: "900px",
            padding: "50px",
            backgroundColor: "#151515",
            borderRadius: "15px",
            color: "#eee",
            border: "2px solid yellow",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bolder",
              textAlign: "center",
            }}
            gutterBottom
          >
            {t("home.analysis_1")}
          </Typography>
          <Typography
            variant="semibold"
            sx={{ textAlign: "center", fontWeight: "bold" }}
          >
            {t("home.analysis_2")}
          </Typography>
        </Box>

        <Box
          sx={{
            ...sectionStyles,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Filter1Icon sx={{ fontSize: "40px", margin: "5px" }} />
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {t("home.follow_reg_1")}
            </Typography>
          </Box>
          <DoubleArrowIcon
            sx={{
              fontSize: "80px",
              margin: "20px",
              transform: { xs: "rotate(90deg)", md: "none" },
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Filter2Icon sx={{ fontSize: "40px", margin: "5px" }} />
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {t("home.follow_reg_2")}
            </Typography>
          </Box>

          <DoubleArrowIcon
            sx={{
              fontSize: "80px",
              margin: "20px",
              transform: { xs: "rotate(90deg)", md: "none" },
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Filter3Icon sx={{ fontSize: "40px", margin: "5px" }} />
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {t("home.follow_reg_3")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const StyledTableCell = styled(TableCell)({
  color: "white",
  textAlign: "center",
  "&": {
    borderBottom: 0,
  },
  "&:not(td)": {
    borderBottom: "1px solid yellow",
    fontWeight: "bolder",
  },
  "&:not(th):first-of-type": {
    fontWeight: "bolder",
    textTransform: "uppercase",
  },
});

export default Section3;
