const setImageBody = (src) => {
  document.getElementsByTagName(
    "body"
  )[0].style.backgroundImage = `url("${src}")`;
  document.getElementsByTagName("body")[0].style.backgroundPosition = "center";
  document.getElementsByTagName("body")[0].style.backgroundSize = "cover";
  document.getElementsByTagName("body")[0].style.backgroundRepeat = "no-repeat";
  document.getElementsByTagName("body")[0].style.backgroundAttachment = "fixed";
  document.getElementById("root").style.background = "rgba(0,0,0,0.5)";
};

const unsetImageBody = () => {
  setTimeout(() => {
    document.getElementsByTagName("body")[0].style.backgroundImage = `none`;
    document.getElementById("root").style.background = "black";
  }, 1);
};
export { setImageBody, unsetImageBody };
