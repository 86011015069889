import React, { useState } from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import MainLogo from "./MainLogo";
import { Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AdditionalButton from "./AdditionalButton";
import RadioButton from "./RadioButton";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import { useLanguage, LANGUAGES } from "../context/use-language";

import { isEmail } from "./../utils/validators";

import privacyFileEN from "../assets/policy/policy-en.rtf";
import privacyFileCN from "../assets/policy/policy-cn.rtf";
import privacyFileRU from "../assets/policy/policy-ru.rtf";
import privacyFileDE from "../assets/policy/policy-de.rtf";
import privacyFileFR from "../assets/policy/policy-fr.rtf";

const POLICY = {
  en: privacyFileEN,
  ru: privacyFileRU,
  cn: privacyFileCN,
  fr: privacyFileFR,
  de: privacyFileDE,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const ERROR_STATE = {
  email: null,
  phone: null,
  password: null,
  confirmPassword: null,
  termsError: null,
};

const SignUpModal = ({ open, handleClose, onSignUp, t }) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState("");
  const [error, setError] = useState(ERROR_STATE);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const { language } = useLanguage();

  const lang = LANGUAGES.find((l) => l.code === language) || "en";

  const onClickPrivacy = () => {
    const privacyFile = POLICY[language];
    window.open(privacyFile, "_blank");
  };

  const removeError = (key) => {
    return setError((s) => ({
      ...s,
      [key]: null,
    }));
  };

  const _onSignUp = async () => {
    if (!email.length) {
      return setError((s) => ({
        ...s,
        email: t('fieldRequired'),
      }));
    }

    if (!isEmail(email)) {
      return setError((s) => ({
        ...s,
        email: t('emailIncorrect'),
      }));
    }

    if (!password) {
      return setError((s) => ({
        ...s,
        password: t('fieldRequired'),
      }));
    }

    if (password !== confirmPassword) {
      return setError((s) => ({
        ...s,
        confirmPassword: t('passwordNotMatch'),
      }));
    }

    if (!phone || phone.length < 7) {
      return setError((s) => ({
        ...s,
        phone: t('fieldRequired'),
      }));
    }

    if (!checked) {
      return setError((s) => ({
        ...s,
        termsError: "true",
      }));
    }
    setLoading(true);
    await onSignUp({ email, password, phone });
    setLoading(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((s) => !s);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      handleClose={handleClose}
    >
      <Box
        sx={{
          ...style,
          background: "linear-gradient(to right, #0e1111, grey)",
          padding: "20px 30px",
          borderRadius: "20px",
          border: "1px solid #fff",
        }}
      >
        <Box>
          <MainLogo />
          <Typography
            variant="subtitle1"
            sx={{
              color: "#ffe599",
              fontSize: 18,
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {t("button.sign_up")}
          </Typography>
          <Typography
            sx={{
              marginBottom: "20px",
              color: "#fff",
              fontSize: 12,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {t("form.sign_up.title")}
          </Typography>
          <StyledInput
            size="normal"
            label={t("form.email")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value.trim());
              removeError("email");
            }}
            error={error.email}
            helperText={error.email}
          />
          <ReactPhoneInput
            value={phone}
            onChange={(v) => {
              setPhone(v);
              removeError("phone");
            }}
            component={StyledInput}
            label={t("phone")}
            country={lang.country_code}
            isValid={!Boolean(error.phone)}
          />
          <StyledInput
            size="normal"
            label={t("form.password")}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value.trim());
              removeError("password");
            }}
            type={showPassword ? "text" : "password"}
            error={error.password}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ color: "#ffe599" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledInput
            size="normal"
            label={t("form.confirm_password")}
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value.trim());
              removeError("confirmPassword");
            }}
            type={showConfirmPassword ? "text" : "password"}
            error={error.confirmPassword}
            helperText={error.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ color: "#ffe599" }}
                  >
                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <RadioButton
              selectedValue={checked}
              setSelectedValue={() => setChecked(!checked)}
            />
            <Typography
              sx={{
                fontSize: 12,
                color: error.termsError ? "red" : "lightblue",
                fontWeight: 600,
                cursor: "pointer",
              }}
              onClick={onClickPrivacy}
            >
              {t("form.terms")}
            </Typography>
          </Box>
          <AdditionalButton
            text={t("button.sign_up")}
            onClick={_onSignUp}
            loading={loading}
          />
        </Box>
      </Box>
    </Modal>
  );
};

const StyledInput = styled(TextField)({
  width: "100%",
  marginBottom: "20px",
  color: "#fff",

  "& label": {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#ffe599",
  },

  "& input": {
    color: "#ffe599",
  },
});

export default SignUpModal;
