import { Box, Typography, TextField, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { GradientListButton } from "./balance-section";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { arrayUnion } from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useProfile } from "../../context/use-profile";
import { iconList } from './balance-section';

const WithdrawSection = () => {
  const [hash, setHash] = useState("");
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { updateProfile, refetchUser, paymentList } = useProfile();
  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get("amount");
  const action = searchParams.get("action");
  const selectedPaySystem = searchParams.get("paySystem");
  const account = searchParams.get("account");
  const { t } = useTranslation();

  const navigate = useNavigate();

  const paySystem = useMemo(() => {
    const findSystem = paymentList.find(
      (item) => item.value === selectedPaySystem
    );
    return findSystem || {};
  }, [selectedPaySystem]);

  const onSendHash = async () => {
    if (!hash) {
      return;
    }

    setLoading(true);

    const data = {
      hashNumber: hash,
      dateCreated: moment().format(),
      status: "pending",
      action,
      amount,
      receiverAccount: account,
    };

    updateProfile({
      transactions: arrayUnion(data),
    })
      .then(() => {
        refetchUser();
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "700px",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          border: "3px solid #435652",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "10px 70px",
              border: "3px solid #435652",
              borderRadius: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bolder", textAlign: "center" }}
            >
              {t("profile.withdraw.withdraw")}
            </Typography>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "200px" } }}>
            <GradientListButton
              disabled
              sx={{
                display: "flex",
                paddingLeft: 0,
                justifyContent: "space-between",
                "&.Mui-disabled": { opacity: "1" },
              }}
            >
              <img src={iconList[paySystem.value]} alt={paySystem.label} width="35px" />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {paySystem.label}
              </Typography>
            </GradientListButton>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
          }}
        >
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.withdraw.text1", { system: paySystem.label, amount })}$
          </Typography>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.withdraw.text2")}
          </Typography>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.withdraw.text3", { system: paySystem.label })}
          </Typography>
          <StyledInput
            sx={{ marginTop: "-17px" }}
            placeholder={t("profile.deposit.address_example")}
            value={hash}
            onChange={(e) => setHash(e.target.value)}
          />
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.withdraw.text4")}
          </Typography>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButton loading={loading} onClick={onSendHash}>
              {t("confirm")}
            </StyledButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundImage: 'url("/crypto.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: "1/1",
            flex: 1,
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              right: 0,
              bottom: 0,
              padding: "20px",
            }}
          >
            <Box
              sx={{
                background: "rgba(0,0,0,0.75)",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bolder", lineHeight: 1, fontSize: "28px" }}
              >
                {t("profile.deposit.text5")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            backgroundImage: 'url("/support.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: "1/1",
            flex: 1,
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              right: 0,
              top: 0,
              padding: "20px",
            }}
          >
            <Box
              sx={{
                background: "rgba(0,0,0,0.75)",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bolder",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {t("profile.deposit.text6")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              right: 0,
              bottom: 0,
              padding: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => navigate("/profile?tab=help")}
              sx={{
                background: "rgba(0,0,0,1)",
                padding: "10px 30px",
                borderRadius: "20px",
                color: "white",
                fontWeight: "bolder",
                fontSize: "24px",
                textTransform: "capitalize",
                border: "1px solid gray",

                "&:hover": {
                  background: "rgba(0,0,0,0.8)",
                },
              }}
            >
              {t("write")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StyledButton = styled(LoadingButton)({
  borderRadius: "15px",
  backgroundColor: "#306c76",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});

const StyledInput = styled(TextField)({
  outline: "none",
  backgroundColor: "transparent",
  border: "3px solid #435652",
  borderRadius: "20px",

  "& label.Mui-focused": {
    color: "black",
  },
  "& label.MuiFormLabel-filled, & label.Mui-focused": {
    top: "0px",
    fontWeight: "bold",
  },
  "& label": {
    top: "-4px",
    fontWeight: "bold",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#708d7f",
    },
  },
  "& input": {
    fontSize: "18px",
    fontWeight: "bold",
    color: "white",
    padding: "5px 30px",
    height: "40px",
  },
  "& fieldset": {
    border: "none",
  },
});

export default WithdrawSection;
