import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  List,
  TextField,
} from "@mui/material";
import { useMemo, useState, useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import WithdrawSection from "../../pages/profile-tab-sections/withdraw-section";
import DepositSection from "../../pages/profile-tab-sections/deposit-section";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { onInputNumber } from "../../utils/validators";
import moment from "moment";

export const iconList = {
  ["BTC"]: "/btc.png",
  ["ETH"]: "/eth.png",
  ["LTC"]: "/ltc.png",
  ["USDTTRC"]: "/teather.png",
  ["USDTERC"]: "/teather.png",
  ["DOGE"]: "/dogecoin.png",
  ["PAYEERUSD"]: "/payeer.png",
  ["PAYEEREUR"]: "/payeer.png",
};

const BalanceSection = memo(function BalanceSectionFunc({
  userProfile,
  paymentList,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action");
  const investType = searchParams.get("investType");
  const [selectedPaySystem, setSelectedPaySystem] = useState("BTC");
  const [selectedAccount, setSelectedAccount] = useState(
    paymentList[0].account
  );
  const [amount, setAmount] = useState(parseFloat(0).toFixed(2));

  const transactions = useMemo(
    () => userProfile?.transactions || [],
    [userProfile]
  );

  const balance = useMemo(() => {
    return [
      {
        title: t("profile.available_balance"),
        balance: userProfile?.balance || 0.0,
      },
      {
        title: t("profile.total_income"),
        balance: userProfile?.totalIncome || 0.0,
      },
      {
        title: t("profile.invested"),
        balance: userProfile?.totalInvestment || 0.0,
      },
      {
        title: t("profile.ref_bonuses"),
        balance: userProfile?.totalRefBonus || 0.0,
      },
    ];
  }, [t, userProfile]);

  const handleDepositClick = useCallback(() => {
    navigate(
      `/profile?tab=balance&action=deposit&paySystem=${selectedPaySystem}&amount=${amount}&account=${selectedAccount}&investType=${investType}`
    );
  }, [amount, navigate, selectedAccount, selectedPaySystem]);

  const handleWithdrawClick = useCallback(() => {
    navigate(
      `/profile?tab=balance&action=withdraw&paySystem=${selectedPaySystem}&amount=${amount}&account=${selectedAccount}&investType=${investType}`
    );
  }, [amount, navigate, selectedAccount, selectedPaySystem]);

  const handleListItemClick = useCallback((val) => {
    setSelectedPaySystem(val);
  }, []);

  const listItemsPaySystem = useMemo(() => {
    return paymentList
      .sort((a, b) => {
        return a.label.charAt(0).localeCompare(b.label.charAt(0));
      })
      .map((item, index) => (
        <GradientListButton
          key={index}
          selected={selectedPaySystem === item.value}
          onClick={() => {
            handleListItemClick(item.value);
            setSelectedAccount(item.account);
          }}
        >
          <ListItemIcon>
            <img src={iconList[item.value]} alt={item.label} width="35px" />
          </ListItemIcon>
          <ListItemText sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {item.label}
            </Typography>
          </ListItemText>
        </GradientListButton>
      ));
  }, [handleListItemClick, selectedPaySystem]);

  const BalanceQuotes = memo(({ balance }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "stretch",
          gap: "10px",
          padding: "20px",
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "20px",
        }}
      >
        {balance.map((item) => (
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {item.title}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
              {item.balance.toFixed(2)}$
            </Typography>
          </Box>
        ))}
      </Box>
    );
  });

  const BalanceState = memo(() => {
    return (
      <Box
        sx={{
          padding: "20px",
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "20px",
        }}
      >
        <img src="/balance-state.png" alt="balance state" width="100%" />
        <Box sx={{ display: "flex" }}>
          <Typography
            variant="h4"
            sx={{ color: "yellow", fontWeight: "bolder", marginRight: "10px" }}
          >
            {t("availableBalance")}
          </Typography>
          <Typography variant="h3" sx={{ fontWeight: "bolder" }}>
            {` ${userProfile?.balance || 0.0}$`}
          </Typography>
        </Box>
      </Box>
    );
  });

  const BalanceCashback = memo(() => {
    return (
      <Box
        sx={{
          padding: "20px",
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "20px",
        }}
      >
        <Typography variant="body" sx={{ fontWeight: "bold" }}>
          {t("profile.balance.cashback")}
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            JUMPSY
          </Typography>
          <img src="/gift.png" alt="cashback" height="70px" />
        </Box>
      </Box>
    );
  });

  const BalanceHistory = memo(() => {
    function createData(name, status, amount, date, type) {
      return { name, status, amount, date, type };
    }

    const rows = useMemo(() => {
      let rowList = [];

      if (transactions.length) {
        rowList = transactions.map((item) => {
          const itemType = item.action === "deposit" ? "deposit" : "withdraw";

          return createData(
            itemType,
            item.status,
            item.amount,
            item.dateCreated,
            item.action
          );
        });
      }

      return rowList.sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    }, []);

    return (
      <TableContainer component={Box}>
        <Table
          sx={{
            borderCollapse: "separate",
            borderSpacing: "0 10px",
          }}
          aria-label="simple table"
        >
          <TableHead sx={{ display: "none" }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  "& td, & th": {
                    border: 0,
                    color: "white",
                    fontWeight: "bold",
                    padding: "10px",
                  },
                  background:
                    "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,165,170,1) 100%);",
                }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.amount}</TableCell>
                <TableCell align="right">
                  {moment(row.date).format("MM.DD.YYYY")}
                </TableCell>
                <TableCell align="right">{row.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  });

  if (action === "deposit") {
    return <DepositSection balance={userProfile?.balance || 0} />;
  }

  if (action === "withdraw") {
    return <WithdrawSection />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "800px",
        gap: "10px",
      }}
    >
      <BalanceQuotes balance={balance} />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            flex: 1,
          }}
        >
          <BalanceState />
          <BalanceCashback />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              padding: "20px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              height: "100%",
              maxHeight: "100%",
              overflow: "auto",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "20px",
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <Typography variant="body" sx={{ fontWeight: "bold" }}>
                  {investType
                    ? t("profile.deposit.invest.title", {
                        investType: investType.toUpperCase(),
                      })
                    : t("profile.deposit.deposit_balance")}
                </Typography>
              </Box>
              <Box>
                <Box sx={{ marginTop: "10px" }}>
                  <List
                    sx={{
                      maxHeight: "250px",
                      overflow: "auto",
                      padding: "0 10px",
                    }}
                    component="nav"
                    aria-label="main mailbox folders"
                  >
                    {listItemsPaySystem}
                  </List>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "0 20px",
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {t("sum")}:
                </Typography>
                <StyledInput
                  size="small"
                  type="number"
                  value={amount}
                  onInput={(e) => {
                    onInputNumber(e, setAmount);
                  }}
                />
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  $
                </Typography>
              </Box>

              <Box sx={{ display: "flex", gap: "40px", padding: "0 10px" }}>
                <GradientButton onClick={handleDepositClick}>
                  {t("deposit")}
                </GradientButton>
                <GradientButton onClick={handleWithdrawClick}>
                  {t("withdraw")}
                </GradientButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
          {t("profile.last_operations")}
        </Typography>
        <Link
          to={"/profile?tab=history"}
          style={{
            color: "white",
            fontWeight: 600,
          }}
        >
          {t("lookAll")}
        </Link>
      </Box>
      <BalanceHistory />
    </Box>
  );
});

const StyledInput = styled(TextField)({
  outline: "none",
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "8px",

  "& label.Mui-focused": {
    color: "black",
  },
  "& label.MuiFormLabel-filled, & label.Mui-focused": {
    top: "0px",
    fontWeight: "bold",
  },
  "& label": {
    top: "-4px",
    fontWeight: "bold",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#708d7f",
    },
  },
  "& input": {
    fontSize: "18px",
    fontWeight: "bold",
    color: "white",
    padding: "5px 10px",
    textAlign: "end",
    height: "40px",
  },
  "& fieldset": {
    border: "none",
  },
});

export const GradientListButton = styled(ListItemButton)({
  background: `linear-gradient(
    90deg,
    rgba(47, 47, 47, 1) 0%,
    rgba(147, 145, 145, 1) 100%
  )`,
  margin: "10px 0",
  borderRadius: "5px",
  padding: "0px 10px",
  "&:hover": {
    outline: "1px solid #76b0ce",
  },
  "&.Mui-selected": {
    outline: "2px solid #76b0ce",
  },
});

const GradientButton = styled(Button)`
  background: linear-gradient(
    90deg,
    rgba(204, 255, 216, 1) 0%,
    rgba(149, 186, 255, 1) 100%
  );
  border-radius: 5px;
  border: 0;
  color: black;
  font-weight: bolder;
  font-size: 17px;
  padding: 5px 20px;
  box-shadow: 0 3px 5px 2px rgba(255, 160, 0, 0.3);
  text-transform: capitalize;
  flex: 1;

  &:hover {
    opacity: 0.9;
  }
`;

export default BalanceSection;
