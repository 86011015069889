import React, { useState, useContext, useEffect } from "react";
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../config/firestore";
import { useAuth } from "./use-auth";

const ProfileContext = React.createContext({
  userProfile: null,
  loading: true,
  updateProfile: ({}) => {},
  refetchUser: () => {},
  paymentList: [],
});

export const ProfileProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const userId = currentUser?.uid || "";

  useEffect(() => {
    if (!userId) {
      return;
    }

    const initProfile = async () => {
      try {
        setLoading(true);

        const userDoc = doc(db, "users", userId);
        const docSnap = await getDoc(userDoc);

        if (docSnap.exists()) {
          const querySnapshot = await getDocs(collection(db, "payment"));
          const paymentData = querySnapshot.docs.map((doc) => doc.data());
          setPaymentList(paymentData);
          setUserProfile(docSnap.data());
        }
      } catch (error) {
        throw error;
      } finally {
        setLoading(false);
      }
    };

    initProfile();
  }, [userId]);

  const refetchUser = async () => {
    const uid = currentUser?.uid;
    const userDocRef = doc(db, "users", uid);
    const userDocSnapshot = await getDoc(userDocRef);

    if (userDocSnapshot.exists()) {
      setUserProfile(userDocSnapshot.data());
    }
  };

  const updateProfile = async (data) => {
    const userRef = doc(db, "users", userId);
    const submitData = {
      ...userProfile,
      ...data,
    };

    await updateDoc(userRef, submitData);
    await refetchUser();
  };

  // useEffect(() => {
  //   if (userProfile) {
  //     const interval = setInterval(async () => {
  //       await refetchUser();
  //     }, 60000);

  //     return () => clearInterval(interval);
  //   }
  // }, [userProfile]);

  return (
    <ProfileContext.Provider
      value={{
        userProfile,
        loading,
        updateProfile,
        refetchUser,
        paymentList,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export const useProfile = () => useContext(ProfileContext);
