import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  loadingBlock: {
    width: 30,
    height: 30,
    backgroundColor: "yellow",
    margin: 10,
    borderRadius: 15,
    animation: "$loadingAnimation 1s ease-in-out infinite",
    "&:nth-child(2)": {
      animationDelay: "0.2s",
    },
    "&:nth-child(3)": {
      animationDelay: "0.4s",
    },
  },
  "@keyframes loadingAnimation": {
    "0%, 100%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(-20px)",
    },
  },
}));

const LoadingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.loadingContainer}>
      <div className={classes.loadingBlock}></div>
      <div className={classes.loadingBlock}></div>
      <div className={classes.loadingBlock}></div>
    </div>
  );
};

export default LoadingPage;
