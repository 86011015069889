import React from "react";
import ProfileMenu from "../components/profile/menu";
import { Box } from "@mui/material";
import { unsetImageBody } from "../utils/dom";

const Profile = () => {
  unsetImageBody();

  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "auto",
          width: "100%",
          color: "white",
          paddingTop: "50px",
        }}
      >
        <ProfileMenu />
      </Box>
    </Box>
  );
};

export default Profile;
