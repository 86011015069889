import { Box, Typography, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useMemo } from "react";

const statusType = {
  COMPLETED: "completed",
  WORK: "in process",
};

const market = ["market", "crypto", "base"];

const comingDate = moment().add(1, "days").format("YYYY-MM-DD");
const currentDate = moment().format("YYYY-MM-DD");

const MyWaysSection = ({ investment = [], t }) => {
  const navigate = useNavigate();

  const displayedContent = useMemo(() => {
    if (investment.length === 0) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box>
            <img src="/graph.png" alt="" width={200} />
          </Box>
          <Typography
            sx={{ marginTop: "20px", maxWidth: "250px", textAlign: "center" }}
          >
            {t("profile.directions.empty")}
          </Typography>
          <StyledButton href="/profile?tab=invest">{t("open")}</StyledButton>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "30px",
            }}
          >
            <Box
              sx={{
                flex: 10,
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                padding: "20px",
                border: "3px solid #5ec2d4",
                borderRadius: "20px",
              }}
            >
              <Typography
                variant="body2"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                {t("profile.directions.text1")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {t("profile.directions.text2")}&gt;
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {t("profile.directions.text3")}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {t("profile.directions.text4")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                flex: 6,
                display: "flex",
                flexDirection: "column",
                gap: "0px",
                padding: "20px",
                border: "3px solid #5ec2d4",
                borderRadius: "20px",
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "bold", textAlign: "center" }}
              >
                {t("profile.directions.text5")}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "10px",
                }}
              >
                <img src="/help.png" alt="" width="100%" />
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }

    return (
      <Box>
        <Box
          sx={{
            borderRadius: "20px",
            color: "white",
            display: "flex",
            flexDirection: "column",
            height: "auto",
            padding: "10px 20px",
            background:
              "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,165,170,1) 100%)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
                {t("profile.directions.text6")}{" "}
                <Typography
                  variant="h4"
                  sx={{ color: "orange", fontWeight: "bolder" }}
                >
                  JUMPSY
                </Typography>
                <StyledButton onClick={() => navigate("/directions")}>
                  {t("profile.directions.text7")}
                </StyledButton>
              </Typography>
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <img src="/ways.png" alt="" width={200} />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            marginTop: "30px",
            maxWidth: "600px",
          }}
        >
          {console.log({investment})}
          {investment
            ?.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated))
            .map((invest, idx) => {
              const image = market.includes(invest.investType)
                ? "/investimg2.png"
                : "/investimg1.png";

              let incomeValue = 0;

              if (invest.income.length) {
                const firstItemDate = invest.income[0].date.split("T")[0];
                console.log(currentDate, firstItemDate);

                if (firstItemDate === currentDate) {
                  incomeValue = 0;
                } else {
                  incomeValue = invest.income.filter(
                    (i) => i.date.split("T")[0] === comingDate
                  )[0].value;
                }
              }

              return (
                <Box
                  key={`${idx + 1}-investment-item}`}
                  sx={{
                    borderRadius: "20px",
                    color: "white",
                    display: "flex",
                    height: "auto",
                    padding: "10px",
                    border: "3px solid #0f4d59",
                    background:
                      "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(11,42,43,1) 100%)",
                  }}
                >
                  <Box
                    sx={{
                      alignItems: "center",
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    <img
                      src={image}
                      alt=""
                      width={200}
                      style={{
                        border: "1px solid #0c6591",
                        margin: "20px 0 20px 20px",
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      flex: 1,
                      paddingLeft: "30px",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: "#8ecaef",
                          textAlign: { xs: "start", md: "end" },
                          textTransform: "uppercase",
                        }}
                      >
                        {invest.investType.toUpperCase()}
                      </Typography>
                    </Box>
                    <BoxContainerValue>
                      <TextLabel variant="h6">{t("sum")}:</TextLabel>
                      <TextValue variant="h6">{invest.amount}$</TextValue>
                    </BoxContainerValue>
                    <BoxContainerValue>
                      <TextLabel variant="h6">{t("profit")}:</TextLabel>
                      <TextValue variant="h6">{incomeValue}$</TextValue>
                    </BoxContainerValue>
                    {/* <BoxContainerValue>
                <TextLabel variant="h6">
                  {invest.investType.toUpperCase()}
                </TextLabel>
              </BoxContainerValue> */}
                    <BoxContainerValue>
                      <TextLabel variant="h6">{t("opened")}:</TextLabel>
                      <TextValue variant="h6" sx={{ color: "white" }}>
                        {moment(invest.dateCreated).format("DD.MM.YYYY")}
                      </TextValue>
                    </BoxContainerValue>
                    <BoxContainerValue sx={{ justifyContent: "flex-start" }}>
                      <TextLabel variant="h6">{t("profile.status")}:</TextLabel>
                      {invest.status === statusType.COMPLETED ? (
                        <TextValue variant="h6" sx={{ color: "orange" }}>
                          {t("finished")}
                        </TextValue>
                      ) : (
                        <TextValue variant="h6" sx={{ color: "green" }}>
                          {t("atWork")}
                        </TextValue>
                      )}
                    </BoxContainerValue>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  }, [investment, t]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "800px",
        gap: "10px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bolder",
          borderBottom: "2px solid yellow",
          maxWidth: "max-content",
          padding: "0 10px",
          marginBottom: "20px",
        }}
      >
        {t("myDirections")}
      </Typography>
      {displayedContent}
    </Box>
  );
};

const BoxContainerValue = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
});

const TextLabel = styled(Typography)({
  fontWeight: "bold",
  marginRight: "10px",
});

const TextValue = styled(Typography)({
  fontWeight: "bold",
  color: "#f9eeb6",
  textAlign: "end",
});

const StyledButton = styled(Button)({
  borderRadius: "10px",
  padding: "8px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",
  margin: "20px auto",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
  background:
    "linear-gradient(90deg, rgba(40,119,164,1) 28%, rgba(2,49,57,1) 100%)",
});

export default MyWaysSection;
