import React, { createContext, useState, useContext } from "react";
import i18n from "../locales/i18n";

const LanguageContext = createContext();

export const LANGUAGES = [
  {
    code: "en",
    name: "EN",
    country_code: "us",
    flag: "🇺🇸",
  },
  {
    code: "de",
    name: "DE",
    country_code: "de",
    flag: "🇩🇪",
  },
  {
    code: "ru",
    name: "RU",
    country_code: "ru",
    flag: "🇷🇺",
  },
  {
    code: "zh",
    name: "中文",
    country_code: "cn",
    flag: "🇨🇳",
  },
  {
    code: "fr",
    name: "FR",
    country_code: "fr",
    flag: "🇫🇷",
  },
];

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
