import { Fragment } from "react";
import { Typography, Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import KeyIcon from "@mui/icons-material/Key";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import HeadphonesIcon from "@mui/icons-material/Headphones";

import bitcoinIcon from "../../assets/home-bitcoin-1.png";
import colors from "../../utils/colors";

const boxItemStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "20px",
  backgroundColor: "transparent",
  border: `1px solid ${colors.yellow}`,
  flex: 1,
  borderRadius: "30px",
  aspectRatio: "1 / 1",
  backdropFilter: "blur(10px)",
  background: "rgba(255,255,255,0.1)",
  width: "100%",
  maxWidth: "300px",
};

const rowStyles = {
  display: "flex",
  flexDirection: { xs: "column", md: "row" },
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "40px",
};

const sectionStyles = {
  margin: "100px 0",
};

const boxTextStyles = {
  fontWeight: 600,
  textAlign: "center",
  fontSize: "22px",
  flex: 1,
  display: "flex",
  alignItems: "center",
};

const h1Styles = {
  paddingBottom: "20px",
  fontWeight: 600,
  fontSize: "50px",
};

const titleStyle = {
  fontWeight: "bolder",
  marginBottom: "20px",
  borderBottom: "4px solid yellow",
  marginRight: { xs: 0, md: "auto" },
  fontSize: { xs: "24px", md: "40px" },
  textAlign: { xs: "center", md: "start" },
  paddingBottom: "10px",
};

const descriptionStyles = {
  fontWeight: 600,
  fontSize: "20px",
};

const Section1 = ({
  t,
  openEducationModal,
  openSignUpModal,
  hasActionButtons,
}) => {
  return (
    <Fragment>
      <Box sx={sectionStyles}>
        <Box
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Box>
            <Box>
              <Typography color={"#fff"} sx={h1Styles}>
                {t("home.intro")}
              </Typography>
              <Typography
                sx={descriptionStyles}
                variant="subtitle1"
                color={"#fff"}
              >
                {t("home.intro2")}
              </Typography>
              <Typography
                sx={descriptionStyles}
                variant="subtitle1"
                color={"#fff"}
              >
                {t("home.intro3")}
              </Typography>
              <Box sx={{ display: "flex", gap: "20px", marginTop: "40px" }}>
                {!hasActionButtons && (
                  <StyledButton onClick={openSignUpModal}>
                    {t("home.button.start")}
                  </StyledButton>
                )}
                <StyledButton
                  onClick={openEducationModal}
                  sx={{ borderColor: "yellow" }}
                  endIcon={<PlayCircleOutlineIcon />}
                >
                  {t("home.button.how_start")}
                </StyledButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...sectionStyles,
          flexGrow: 1,
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={rowStyles}>
          <Box sx={boxItemStyles}>
            <Typography variant="subtitle1" color={"#fff"} sx={boxTextStyles}>
              {t("home.partners_count")}
            </Typography>
            <HandshakeIcon sx={{ fontSize: 60, color: "#fff" }} />
          </Box>
          <Box sx={boxItemStyles}>
            <Typography variant="subtitle1" color={"#fff"} sx={boxTextStyles}>
              {t("home.insurance_fund")}
            </Typography>
            <AccountBalanceIcon sx={{ fontSize: 60, color: "#fff" }} />
          </Box>
          <Box sx={boxItemStyles}>
            <Typography variant="subtitle1" color={"#fff"} sx={boxTextStyles}>
              {t("home.multiple_levels_of_protection")}
            </Typography>
            <KeyIcon sx={{ fontSize: 60, color: "#fff" }} />
          </Box>
          <Box sx={boxItemStyles}>
            <Typography variant="subtitle1" color={"#fff"} sx={boxTextStyles}>
              {t("home.support_24_7")}
            </Typography>
            <HeadphonesIcon sx={{ fontSize: 60, color: "#fff" }} />
          </Box>
        </Box>
      </Box>
      <Box sx={sectionStyles}>
        <Box
          sx={{
            padding: "30px 0",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
            }}
          >
            <Box sx={{ flex: 7, display: "flex", justifyContent: "center" }}>
              <img src={bitcoinIcon} alt="" width="80%" />
            </Box>
            <Box
              sx={{
                flex: 10,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                color: "#eee",
              }}
            >
              <Typography variant="h4" sx={titleStyle}>
                {t("home.about_company")}
              </Typography>
              <Typography
                variant="subtitle1"
                color={"#fff"}
                sx={{ fontWeight: 600, textAlign: "left", width: "100%" }}
              >
                {t("home.about_company_2")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

const StyledButton = styled(Button)({
  border: "1px solid orange",
  borderRadius: "10px",
  padding: "10px 50px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
  },
});

export default Section1;
