import React, { useEffect } from "react";
import { Box, Container, Typography, Button } from "@mui/material";
import { setImageBody } from "../utils/dom";
import Footer from "../components/Footer";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import ScrollTop from "../components/ScrollToTop";
import Link from "@mui/material/Link";
import { useAuth } from "../context/use-auth";
import { useNavigate } from "react-router-dom";
import SignUpModal from "../components/SignUpModal";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "20px",
  padding: "50px",
  backgroundColor: "#151515",
  borderRadius: "15px",
  color: "#eee",
  border: "2px solid yellow",
  flex: 1,
};

const DirItem = ({
  img,
  imgTitle,
  title,
  text,
  companyImg,
  reverse = false,
  url = "",
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: "20px",
      }}
    >
      <ScrollTop />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          order: { xs: "1", md: reverse ? 2 : 1 },
        }}
      >
        <Box sx={{ flex: "0 0 50px" }}>
          <Typography
            variant="h4"
            sx={{
              color: "#3ab33a",
              fontWeight: "bolder",
              textAlign: { xs: "left", md: reverse ? "right" : "left" },
            }}
          >
            {imgTitle}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, paddingTop: "20px" }}>
          <img
            src={img}
            alt=""
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          order: { xs: 2, md: reverse ? 1 : 2 },
        }}
      >
        <Box
          sx={{
            flex: "0 0 50px",
            borderBottom: "3px solid yellow",
            padding: "0 10px",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bolder",
              textAlign: { xs: "left", md: reverse ? "right" : "left" },
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            paddingTop: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              textAlign: { xs: "left", md: reverse ? "right" : "left" },
            }}
          >
            {text}
          </Typography>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: {
                xs: "flex-end",
                md: reverse ? "flex-start" : "flex-end",
              },
            }}
          >
            <Box>
              <Link href={url} rel="noopener" target="_blank">
                <img src={companyImg} alt="" width="100px" />
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Directions = () => {
  const [isOpenSignUpModal, openSignUpModal] = React.useState(false);

  setImageBody("/directions/background.jpg");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { signUp, currentUser } = useAuth();

  const redirect = () => {
    if (!currentUser) {
      return openSignUpModal(true);
    }

    return navigate("/profile");
  };

  const onSignUp = (data) => {
    signUp(data).then(() => {
      openSignUpModal(false);
    });
  };

  useEffect(() => {
    let timeout;

    if (window.location.href.includes("tool")) {
      const toolNumber = window.location.href.split("tool=")[1];
      const element = document.getElementById(`tool-${toolNumber}`);

      if (element) {
        timeout = setTimeout(() => {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 500);
      }
    } else {
      timeout = setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "auto",
          width: "100%",
          color: "white",
          paddingTop: "50px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: "1200px",
            padding: { xs: "10px", md: 0 },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              color: "#eee",
              maxWidth: "700px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bolder",
                color: "yellow",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              JUMPSY
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              {t("directions.text_1")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "stratch",
              justifyContent: "center",
              gap: "20px",
              marginTop: "100px",
            }}
          >
            <Box sx={cardStyle}>
              <Typography variant="h6">{t("directions.text_2")}</Typography>
            </Box>
            <Box sx={cardStyle}>
              <Typography variant="h6">{t("directions.text_3")}</Typography>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            background: "rgba(0, 255, 0, 0.3)",
            backdropFilter: "blur(10px)",
            margin: "100px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            {t("directions.text_4")}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", maxWidth: "700px", textAlign: "center" }}
          >
            {t("directions.text_5")}
          </Typography>
        </Box>
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: "800px",
            padding: { xs: "10px", md: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
              margin: "100px auto",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <img src="/directions/money.png" alt="" width="100%" />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                color: "#eee",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bolder",
                  textAlign: { xs: "center", md: "end" },
                }}
              >
                {t("directions.text_6")}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bolder",
                  textAlign: { xs: "center", md: "end" },
                }}
              >
                JUMPSY
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="h6"
            sx={{
              fontWeight: "bold",
              textAlign: { xs: "center", md: "end" },
              margin: "100px auto",
            }}
          >
            {t("directions.text_7")}
          </Typography>

          <Box
            sx={{
              margin: "100px auto",
              display: "flex",
              flexDirection: "column",
              gap: "50px",
            }}
          >
            <Box id="tool-1">
              <DirItem
                img="/directions/img1.png"
                companyImg="/directions/santos.png"
                imgTitle={t("home.financial_tool_item_1")}
                title="Santos Limited"
                text={t("directions.text_8")}
                url="https://www.santos.com/"
              />
            </Box>
            <Box id="tool-2">
              <DirItem
                reverse
                img="/directions/img2.jpg"
                companyImg="/directions/sundrive.png"
                imgTitimgTitle={t("home.financial_tool_item_3")}
                title="SunDrive"
                text={t("directions.text_9")}
                url="https://www.sundrivesolar.com/"
              />
            </Box>
            <Box id="tool-3">
              <DirItem
                img="/directions/img3.jpg"
                companyImg="/directions/riotinto.png"
                imgTitle={t("home.financial_tool_item_2")}
                title="Rio Tinto Group"
                text={t("directions.text_10")}
                url="https://www.riotinto.com/"
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              margin: "100px auto",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="h4"
                sx={{ fontWeight: "bolder", marginBottom: "15px" }}
              >
                JUMPSY
              </Typography>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {t("directions.text_11")}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <StyledButton onClick={redirect}>
                {t("directions.text_12")}
              </StyledButton>
            </Box>
          </Box>
          <SignUpModal
            open={isOpenSignUpModal}
            handleClose={() => openSignUpModal(false)}
            onSignUp={onSignUp}
            t={t}
          />
          <Footer t={t} />
        </Container>
      </Box>
    </Box>
  );
};

const StyledButton = styled(Button)({
  backgroundColor: "orange",
  borderRadius: "10px",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    backgroundColor: "orange",
    opacity: 0.8,
  },
});

export default Directions;
