import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import MainLogo from "./MainLogo";
import { Typography, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AdditionalButton from "./AdditionalButton";
import RadioButton from "./RadioButton";
import colors from "../utils/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
};

const ERROR_STATE = {
  email: "",
  password: "",
};

const SignInModal = ({ open, handleClose, onSignIn, onToggleSignUp, t }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState("");
  const [error, setError] = useState(ERROR_STATE);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeError = (key) => {
    return setError((s) => ({
      ...s,
      [key]: null,
    }));
  };

  const clearState = () => {
    setError(ERROR_STATE);
    setPassword("");
    setEmail("");
  };

  const _onSignIn = async () => {
    if (!email) {
      return setError((s) => ({
        ...s,
        email: t("fieldRequired"),
      }));
    }

    if (!password) {
      return setError((s) => ({
        ...s,
        password: t("fieldRequired"),
      }));
    }

    setLoading(true);

    const res = await onSignIn({
      email,
      password,
    });

    setLoading(false);

    if (res) {
      handleClose();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((s) => !s);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      handleClose={() => {
        handleClose();
        clearState();
      }}
    >
      <Box
        sx={{
          ...style,
          background: "linear-gradient(to right, #0e1111, grey)",
          padding: "20px 30px",
          borderRadius: "20px",
          border: "1px solid #fff",
        }}
      >
        <Box>
          <MainLogo />
          <Typography
            variant="subtitle1"
            sx={{
              color: "#ffe599",
              fontSize: 18,
              fontWeight: 600,
              marginBottom: "10px",
            }}
          >
            {t("button.sign_in")}
          </Typography>
          <Typography
            sx={{
              marginBottom: "20px",
              color: "#fff",
              fontSize: 12,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            {t("form.sign_in.title")}
          </Typography>
          <StyledInput
            size="normal"
            label={t("form.email")}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              removeError("email");
            }}
            error={error.email}
            helperText={error.email}
          />
          <StyledInput
            size="normal"
            label={t("form.password")}
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              removeError("password");
            }}
            error={error.password}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    sx={{ color: "#ffe599" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "20px",
              width: "100%",
            }}
          >
            <RadioButton
              selectedValue={checked}
              setSelectedValue={() => setChecked(!checked)}
            />
            <Typography sx={{ fontSize: 12, color: "#fff", fontWeight: 600 }}>
              {t("remember_me")}
            </Typography>
          </Box>
          <AdditionalButton
            text={t("button.sign_in")}
            onClick={_onSignIn}
            loading={loading}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                color: colors.white,
                fontSize: "12px",
                flexDirection: "row",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {t("how_to_start")}
              <Typography
                sx={{
                  marginLeft: "5px",
                  color: colors.yellow,
                  fontSize: "12px",
                  cursor: "pointer",
                }}
                onClick={onToggleSignUp}
              >
                {t("create_account")}
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const StyledInput = styled(TextField)({
  width: "100%",
  marginBottom: "20px",
  color: "#fff",

  "& label": {
    fontSize: "15px",
    fontWeight: "bold",
    color: "#ffe599",
  },

  "& input": {
    color: "#ffe599",
  },
});

export default SignInModal;
