import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMemo, useEffect } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

const TeamSection = ({
  userId = "",
  referralList = [],
  rank = 1,
  refIncome = 0.0,
  refBonus = 0.0,
}) => {
  const { t } = useTranslation();

  const refLink = useMemo(() => {
    return `${window.location.origin}/register?ref=${userId}`;
  }, []);

  const refQuotes = useMemo(() => {
    return [
      { title: t("profile.team.partners"), balance: referralList.length },
      {
        title: t("profile.team.ref_profit"),
        balance: refIncome,
        type: "money",
      },
      {
        title: t("profile.team.bonus_profit"),
        balance: refBonus,
        type: "money",
      },
    ];
  }, [t, referralList, refIncome, refBonus]);

  const PartnerCard = ({ level = 1, referralList = [] }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          gap: "10px",
          padding: "20px",
          background: "rgba(255, 255, 255, 0.1)",
          borderRadius: "20px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {t("profile.team.partners_level", { level })}
        </Typography>
        {referralList.length === 0 ? (
          <Typography variant="h6">
            {t("profile.team.partners_level1_desc")}
          </Typography>
        ) : (
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            <Box>
              <TableContainer component={Box}>
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: "separate",
                    borderSpacing: "0 10px",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& td, & th": {
                          border: 0,
                          color: "white",
                          fontWeight: "bold",
                          padding: "10px",
                          borderRight: "1px solid lightgray",

                          "&:last-child": {
                            borderRight: "none",
                          },
                        },
                        background:
                          "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,165,170,1) 100%);",
                      }}
                    >
                      <TableCell
                        align="center"
                        sx={{
                          textTransform: "capitalize",
                        }}
                      >
                        {t("email")}
                      </TableCell>
                      <TableCell align="center">{t("date")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {referralList.map((refItem) => (
                      <TableRow
                        key={`${refItem.email}-${refItem.date}}`}
                        sx={{
                          "& td, & th": {
                            border: 0,
                            color: "white",
                            fontWeight: "bold",
                            padding: "10px",
                          },
                          background:
                            "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,165,170,1) 100%);",
                        }}
                      >
                        <TableCell align="center">{refItem.email}</TableCell>
                        <TableCell align="center">{refItem.date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Typography>
        )}
      </Box>
    );
  };

  const copyRefLink = () => {
    navigator.clipboard.writeText(refLink);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "800px",
        paddingBottom: "200px",
        gap: "10px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bolder",
          borderBottom: "2px solid yellow",
          maxWidth: "max-content",
          padding: "0 10px",
          marginBottom: "20px",
        }}
      >
        {t("profile.menu.team")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100% !important",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0px",
              padding: "20px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("profile.team.text1")}
            </Typography>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px",
              }}
            >
              <img src="/graph.png" alt="graph" width="60%" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "stretch",
              gap: "10px",
              padding: "20px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
            }}
          >
            {refQuotes.map((item) => (
              <Box sx={{ flex: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {item.title}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
                  {item.type === "money"
                    ? item.balance.toFixed(2) + "$"
                    : item.balance}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              background:
                "linear-gradient(90deg, rgba(0,0,0,1) 7%, rgba(255,0,0,1) 100%)",
              borderRadius: "20px",
              padding: "20px",
              color: "#fff",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("profile.team.your_team")}
            </Typography>
            <ArrowForwardIcon
              sx={{
                marginLeft: "10px",
              }}
              width={30}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            flex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              alignItems: "center",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "60%", width: "60%" }}
              value={refLink}
              viewBox={`0 0 256 256`}
            />
            <Typography variant="body" sx={{ fontWeight: "bold" }}>
              {refLink}
            </Typography>
            <StyledButton variant="text" onClick={copyRefLink}>
              <AttachFileIcon width={25} />
              {t("copy")}
            </StyledButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "20px",
              background: "rgba(255, 255, 255, 0.1)",
              borderRadius: "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StarIcon
                sx={{
                  marginRight: "5px",
                  height: "40px",
                  width: "40px",
                  color: "orange",
                }}
              />

              <Typography
                variant="h4"
                sx={{
                  color: "orange",
                  fontWeight: "bolder",
                }}
              >
                Rank {rank}
              </Typography>
            </Box>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
                  {referralList.length}
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {t("profile.team.all_partners")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
                  {refIncome.toFixed(2)}$
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {t("earned")}
                </Typography>
              </Box>
            </Box>
            <img src="/pazzle.png" alt="graph" width="30%" />
            <Typography
              variant="body"
              sx={{
                fontWeight: "bold",
                textAlign: "right",
                color: "#b18c1e",
                fontSize: "20px",
              }}
            >
              {t("profile.team.text2")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <PartnerCard level={rank} referralList={referralList} />
    </Box>
  );
};

const StyledButton = styled(Button)({
  borderRadius: "15px",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    backgroundColor: "rgba(255,255,255, 0.1)",
  },
});

export default TeamSection;
