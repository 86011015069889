import React from "react";
import Button from "@mui/material/Button";
import colors from "../utils/colors";
import { Typography } from "@mui/material";

const MainButton = ({ text, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        borderColor: colors.yellow,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "8px",
        ":hover": {
          borderColor: colors.yellow,
          backgroundColor: "#000",
        },
        fontSize: "14px",
        fontWeight: "700",
        textTransform: "none",
        display: "flex",
        flex: 1,
        minWidth: "150px",
        maxWidth: "150px",
      }}
    >
      <Typography color={"#fff"} variant="subtitle1" sx={{ fontWeight: 600 }}>
        {text}
      </Typography>
    </Button>
  );
};

export default MainButton;
