import React, { useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Box,
  Grid,
  Button,
  Popper,
  Fade,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";

import { styled, useTheme } from "@mui/material/styles";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { setImageBody } from "../utils/dom";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Footer from "../components/Footer";
import SignUpModal from "../components/SignUpModal";
import EducationModal from "../components/EducationModal";
import ScrollTop from "../components/ScrollToTop";
import { useAuth } from "../context/use-auth";

const AdaptiveTimeline = ({ children }) => {
  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Timeline
        position="alternate"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        {children}
      </Timeline>
      <Timeline position="right" sx={{ display: { xs: "flex", md: "none" } }}>
        {children}
      </Timeline>
    </Box>
  );
};
const PopoverTimeline = ({ title, children }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  return (
    <Box>
      <ScrollTop />

      <TimelineTypography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          padding: { xs: "5px 15px", md: "15px 40px" },
          fontSize: { xs: "18px", md: "24px" },
        }}
      >
        {title}
      </TimelineTypography>
      <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                border: "1px solid gray",
                p: 2,
                bgcolor: "#151515",
                color: "white",
                borderRadius: "15px",
                maxWidth: "400px",
              }}
            >
              {children}
            </Box>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

const AboutUs = () => {
  const [isOpenSignUpModal, openSignUpModal] = React.useState(false);
  const [isOpenEducationModal, openEducationModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { signUp, currentUser } = useAuth();

  const theme = useTheme();
  const { t } = useTranslation();
  setImageBody("/about-us/graph.jpg");

  const StyledTimelineItem = styled(TimelineItem)({
    [theme.breakpoints.down("md")]: {
      "&:before": {
        flex: 0,
      },
    },
    [theme.breakpoints.up("md")]: {
      "&:before": {
        flex: 1,
      },
    },
  });

  const onSignUp = (data) => {
    signUp(data).then(() => {
      openSignUpModal(false);
    });
  };


  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <SignUpModal
        open={isOpenSignUpModal}
        handleClose={() => openSignUpModal(false)}
        onSignUp={onSignUp}
        t={t}
      />
      <EducationModal
        open={isOpenEducationModal}
        setOpen={openEducationModal}
      />
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "auto",
          width: "100%",
          color: "white",
          paddingTop: "50px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: "1200px",
            padding: { xs: "10px", md: 0 },
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bolder" }} gutterBottom>
            {t("about_us")}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ maxWidth: "500px", fontWeight: "bold" }}
          >
            {t("about_us.text_1")}
          </Typography>

          <Box sx={{ display: "flex", gap: "20px", marginTop: "40px" }}>
            {!currentUser && (
              <StyledButton onClick={() => openSignUpModal(true)}>
                {t("start")}
              </StyledButton>
            )}
            <StyledButton
              onClick={() => openEducationModal(true)}
              sx={{ borderColor: "yellow" }}
              endIcon={<PlayCircleOutlineIcon />}
            >
              {t("home.button.how_start")}
            </StyledButton>
          </Box>

          <Typography
            variant="h4"
            sx={{
              fontWeight: "bolder",
              textAlign: "center",
              marginTop: "50px",
            }}
            gutterBottom
          >
            {t("about_us.text_2")}
          </Typography>

          <Box my={4}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_3")}
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_4")}
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_5")}
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_6")}
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_7")}
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_8")}
                </StyledPaper>
              </Grid>
              <Grid item xs={12} md={4}>
                <StyledPaper elevation={3} sx={{ p: 2 }}>
                  {t("about_us.text_9")}
                </StyledPaper>
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              maxWidth: "900px",
              margin: "100px auto",
              padding: "50px",
              backgroundColor: "#151515",
              borderRadius: "15px",
              border: "2px solid gray",
            }}
          >
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "bold", color: "#eee" }}
            >
              {t("about_us.text_10")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "bold", color: "#eee" }}
            >
              {t("about_us.text_11")}
            </Typography>
            <Typography
              variant="body1"
              sx={{ textAlign: "center", fontWeight: "bold", color: "#eee" }}
            >
              {t("about_us.text_12")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              gap: "30px",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <img src="/about-us/comp.png" alt="" width="100%" />
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                color: "#eee",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bolder",
                  color: "yellow",
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                JUMPSY
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "bold",
                  textAlign: { xs: "center", md: "start" },
                }}
              >
                {t("about_us.text_13")}
              </Typography>
            </Box>
          </Box>

          <ScrollAnimation animateIn="fadeIn" delay={100}>
            <>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  maxWidth: "900px",
                  margin: "100px auto",
                  padding: "50px",
                  backgroundColor: "#151515",
                  borderRadius: "15px",
                  color: "#eee",
                  border: "2px solid yellow",
                }}
              >
                <Typography variant="body1">
                  {t("about_us.text_14")}{" "}
                  <span style={{ fontWeight: "bold", color: "white" }}>
                    JUMPSY Financial Company
                  </span>{" "}
                  {t("about_us.text_15")}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box
                  sx={{
                    flex: { xs: "auto", md: "0 0 450px" },
                    overflow: "hidden",
                    borderRadius: "0px",
                    border: "1px solid gray",
                  }}
                >
                  <img src="/about-us/person.png" alt="" width="100%" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: { xs: 0, md: "50px" },
                    marginTop: { xs: "20px", md: 0 },
                    gap: "20px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bolder",
                      marginBottom: "20px",
                      borderBottom: "3px solid orange",
                      marginRight: { xs: 0, md: "auto" },
                      fontSize: { xs: "32px", md: "48px" },
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    {t("about_us.text_18")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    {t("about_us.text_16")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      textAlign: { xs: "center", md: "start" },
                    }}
                  >
                    {t("about_us.text_17")}{" "}
                    <span style={{ color: "yellow" }}>
                      {t("about_us.text_18")}
                    </span>{" "}
                    {t("about_us.text_19")}{" "}
                    <span style={{ color: "yellow" }}>
                      JUMSPY Financial Company
                    </span>{" "}
                    {t("about_us.text_20")}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ margin: "100px 0" }}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} md={4}>
                    <QuoteBox>
                      <Box sx={{ position: "absolute", left: 10, top: 10 }}>
                        <FormatQuoteIcon fontSize="medium" />
                      </Box>
                      <Typography sx={{ color: "lightgray" }}>
                        “{t("about_us.text_21")}”
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ color: "gray", textAlign: "end" }}
                        >
                          {t("about_us.text_22")}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "gray", textAlign: "end" }}
                        >
                          ({t("about_us.text_23")})
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 10,
                          bottom: 10,
                          transform: "rotate(180deg)",
                        }}
                      >
                        <FormatQuoteIcon fontSize="medium" />
                      </Box>
                    </QuoteBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <QuoteBox>
                      <Box sx={{ position: "absolute", left: 10, top: 10 }}>
                        <FormatQuoteIcon fontSize="medium" />
                      </Box>
                      <Typography sx={{ color: "lightgray" }}>
                        “{t("about_us.text_24")}”
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ color: "gray", textAlign: "end" }}
                        >
                          {t("about_us.text_25")}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "gray", textAlign: "end" }}
                        >
                          ({t("about_us.text_26")})
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 10,
                          bottom: 10,
                          transform: "rotate(180deg)",
                        }}
                      >
                        <FormatQuoteIcon fontSize="medium" />
                      </Box>
                    </QuoteBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <QuoteBox>
                      <Box sx={{ position: "absolute", left: 10, top: 10 }}>
                        <FormatQuoteIcon fontSize="medium" />
                      </Box>
                      <Typography sx={{ color: "lightgray" }}>
                        "{t("about_us.text_27")}"
                      </Typography>
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ color: "gray", textAlign: "end" }}
                        >
                          {t("about_us.text_28")}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "gray", textAlign: "end" }}
                        >
                          ({t("about_us.text_29")})
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          right: 10,
                          bottom: 10,
                          transform: "rotate(180deg)",
                        }}
                      >
                        <FormatQuoteIcon fontSize="medium" />
                      </Box>
                    </QuoteBox>
                  </Grid>
                </Grid>
              </Box>
            </>
          </ScrollAnimation>

          <ScrollAnimation animateIn="fadeIn" delay={100}>
            <>
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bolder",
                  textAlign: "center",
                  marginTop: "50px",
                }}
                gutterBottom
              >
                {t("about_us.text_30")}
              </Typography>

              <AdaptiveTimeline>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`1 ${t("quarter")} 2023`}>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_31")}
                      </Typography>
                      <Typography>{t("about_us.text_32")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`2 ${t("quarter")} 2023`}>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_33")}
                      </Typography>
                      <Typography>{t("about_us.text_34")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`3 ${t("quarter")} 2023`}>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_35")}
                      </Typography>
                      <Typography>{t("about_us.text_36")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`4 ${t("quarter")} 2023`}>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_37")}
                      </Typography>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_38")}
                      </Typography>
                      <Typography>{t("about_us.text_39")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`1 ${t("quarter")} 2024`}>
                      <Typography>{t("about_us.text_40")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`2 ${t("quarter")} 2024`}>
                      <Typography>{t("about_us.text_41")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`3 ${t("quarter")} 2024`}>
                      <Typography>{t("about_us.text_42")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`4 ${t("quarter")} 2024`}>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_43")}
                      </Typography>
                      <Typography>{t("about_us.text_44")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`1 ${t("quarter")} 2025`}>
                      <Typography>{t("about_us.text_45")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`2 ${t("quarter")} 2025`}>
                      <Typography>{t("about_us.text_46")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={`3 ${t("quarter")} 2025`}>
                      <Typography sx={{ mb: 1 }}>
                        {t("about_us.text_47")}
                      </Typography>
                      <Typography>{t("about_us.text_48")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
                <StyledTimelineItem>
                  <TimelineSeparator>
                    <StyledTimelineDot />
                    <StyledTimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <PopoverTimeline title={t("about_us.text_49")}>
                      <Typography>{t("about_us.text_50")}</Typography>
                    </PopoverTimeline>
                  </TimelineContent>
                </StyledTimelineItem>
              </AdaptiveTimeline>
            </>
          </ScrollAnimation>

          <Footer t={t} />
        </Container>
      </Box>
    </Box>
  );
};

const StyledButton = styled(Button)({
  border: "1px solid orange",
  borderRadius: "10px",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
  },
});

const StyledPaper = styled(Paper)({
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(10px)",
  color: "#eee",
  borderRadius: "10px",
  border: "1px solid gray",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  fontWeight: "bold",
});

const QuoteBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "30px",
  borderRadius: "15px",
  position: "relative",
  background: "#151515",
  height: "100%",
  justifyContent: "space-between",
  gap: "20px",
});

const StyledTimelineDot = styled(TimelineDot)({
  backgroundColor: "orange",
  padding: "15px",
  border: "3px solid white",
  margin: 0,
});

const StyledTimelineConnector = styled(TimelineConnector)({
  width: "5px",
  background: "white",
  height: "65px",
  borderTop: "10px solid orange",
});

const TimelineTypography = styled(Typography)({
  color: "orange",
  fontWeight: "bold",
  marginTop: "-15px",
  cursor: "pointer",
  transition: "0.3s",
  display: "inline-block",
  background: "rgba(255,255,255,0.2)",
  borderRadius: "15px",
  backdropFilter: "blur(10px)",
  "&:hover": {
    opacity: 0.8,
  },
});

export default AboutUs;
