import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const themeApp = createTheme({
  typography: {
    fontFamily: "Lato, sans-serif",
    allVariants: {
      letterSpacing: "0.5px",
    },
  },
  spacing: 0,
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 0,
          boxSizing: "border-box",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
          boxSizing: "border-box",
        },
      },
    },
  },
});

const theme = responsiveFontSizes(themeApp);

export default theme;
