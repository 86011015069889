import { Box, Typography, TextField, Button, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState, useMemo, useCallback, useEffect } from "react";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import useUploadImage, { IMAGE_TYPES } from "../../utils/upload-image";
import { useProfile } from "../../context/use-profile";
import { useTranslation } from "react-i18next";
import ReactPhoneInput from "react-phone-input-material-ui";
import { useLanguage, LANGUAGES } from "../../context/use-language";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NotificationManager } from "react-notifications";

const ProfileSection = ({ userProfile }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const action = searchParams.get("action");
  const navigate = useNavigate();

  const isVerified = useMemo(() => {
    return userProfile?.isVerified || false;
  }, [userProfile]);

  const balance = useMemo(() => {
    return [
      {
        title: t("profile.available_balance"),
        balance: userProfile?.balance || 0.0,
      },
      {
        title: t("profile.total_income"),
        balance: userProfile?.totalIncome || 0.0,
      },
      {
        title: t("profile.invested"),
        balance: userProfile?.totalInvestment || 0.0,
      },
      {
        title: t("profile.ref_bonuses"),
        balance: userProfile?.totalRefBonus || 0.0,
      },
    ];
  }, [t, userProfile]);

  const VerifyBlock = () => {
    return (
      <Box
        sx={{
          borderRadius: "20px",
          color: "white",
          display: "flex",
          flexDirection: "column",
          width: "350px",
          height: "auto",
          padding: "10px 20px",
          backgroundColor: "#031f22",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{ color: "#f9eeb6", fontWeight: "bolder" }}
            >
              {t("profile.status")}:
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: "bolder" }}>
              {t("notVerified")}
            </Typography>
          </Box>
          <Box>
            <VerifiedUserIcon
              sx={{ width: 48, height: 48, color: "#f9eeb6" }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const StyledInput = styled(TextField)({
    outline: "none",
    backgroundColor: "transparent",
    borderRadius: "8px",

    "& label.Mui-focused": {
      color: "black",
    },
    "& label.MuiFormLabel-filled, & label.Mui-focused": {
      fontWeight: "bold",
      top: 0,
    },
    "& label": {
      top: "-7px",
      fontWeight: "bold",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#376499",
      },
    },
    "& input": {
      fontWeight: "bold",
      color: "#303030",
      padding: "8.5px 14px",
    },
    "& fieldset": {
      border: "1px solid #376499",
    },
  });

  const VerifyForm = ({ formValues, actions }) => {
    const { language } = useLanguage();
    const lang = LANGUAGES.find((l) => l.code === language) || "en";

    return (
      <Box sx={{ padding: "10px", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            borderRadius: "20px",
            background: "rgba(255, 255, 255, 0.2)",
            padding: "20px 10px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: { xs: "100%" },
          }}
        >
          <StyledInput
            label={t("fullName")}
            value={formValues.name}
            onChange={(e) => {
              actions.setName(e.target.value);
            }}
            error={formValues.error.name}
          />
          <StyledInput
            label={t("dateOfBirth")}
            value={formValues.dateOfBirth}
            onChange={(e) => {
              actions.setDateOfBirth(e.target.value);
            }}
            error={formValues.error.dateOfBirth}
          />
          <StyledInput
            label={t("country")}
            value={formValues.country}
            onChange={(e) => {
              actions.setCountry(e.target.value);
            }}
            error={formValues.error.country}
          />
          <StyledInput
            label={t("city")}
            value={formValues.city}
            onChange={(e) => {
              actions.setCity(e.target.value);
            }}
            error={formValues.error.city}
          />
          <StyledInput
            label={t("street")}
            value={formValues.street}
            onChange={(e) => {
              actions.setStreet(e.target.value);
            }}
            error={formValues.error.street}
          />
          <StyledInput
            label={t("postalCode")}
            value={formValues.postCode}
            onChange={(e) => {
              actions.setPostCode(e.target.value);
            }}
            error={formValues.error.postCode}
          />
          <ReactPhoneInput
            value={formValues.phone}
            onChange={(v) => {
              actions.setPhone(v);
            }}
            component={StyledInput}
            label={t("phone")}
            country={lang.country_code}
            error={formValues.error.phone}
          />
          <StyledInput
            label={t("emailAddress")}
            value={formValues.email}
            disabled
            onChange={(e) => {
              actions.setEmail(e.target.value);
            }}
          />
        </Box>
      </Box>
    );
  };

  const ImageUploadForm = () => {
    const [fileName, setFileName] = useState(null);
    const { userProfile, updateProfile } = useProfile();
    const { handleUpload } = useUploadImage(IMAGE_TYPES.verification);
    const handleImageChange = async (e) => {
      const file = e.target.files[0];

      if (file) {
        setFileName(file.name);
        const body = await handleUpload(file, userProfile.uid);
        updateProfile(body);
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
          flex: 1,
        }}
      >
        <img
          src="/idcard.png"
          alt="idcard"
          width={300}
          style={{ margin: "10px 0" }}
        />
        <Box
          sx={{
            borderRadius: "15px",
            background: "#303030",
            border: "4px solid #2d5655",
            padding: "10px",
          }}
        >
          <Typography variant="body2">{t("profile.upload_pasport")}</Typography>
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography sx={{ marginRight: "5px" }} variant="body2">
              {fileName ? fileName : "(.JPG), (.PNG)"}
            </Typography>
            <Button
              sx={{ color: "#303030", backgroundColor: "#eee" }}
              variant="contained"
              color="inherit"
              component="label"
            >
              {t("profile.upload_media")}
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  const ERRORS = {
    email: false,
    name: false,
    dateOfBirth: false,
    country: false,
    city: false,
    street: false,
    postCode: false,
    phone: false,
  };

  const VerifyFormBlock = () => {
    const { userProfile, updateProfile } = useProfile();

    const [name, setName] = useState(userProfile.name);
    const [dateOfBirth, setDateOfBirth] = useState(userProfile.dateOfBirth);
    const [country, setCountry] = useState(userProfile.country);
    const [city, setCity] = useState(userProfile.city);
    const [street, setStreet] = useState(userProfile.street);
    const [postCode, setPostCode] = useState(userProfile.postCode);
    const [phone, setPhone] = useState(userProfile.phone);
    const [email, setEmail] = useState(userProfile.email);
    const [error, setError] = useState(ERRORS);
    const [loading, setLoading] = useState(false);

    const clearError = (key) => {
      setError((prev) => ({
        ...prev,
        [key]: false,
      }));
    };

    const _updateCurrentUser = async () => {
      if (!name) {
        return setError((prev) => ({
          ...prev,
          name: true,
        }));
      }

      if (!dateOfBirth) {
        return setError((prev) => ({
          ...prev,
          dateOfBirth: true,
        }));
      }

      if (!country) {
        return setError((prev) => ({
          ...prev,
          country: true,
        }));
      }

      if (!city) {
        return setError((prev) => ({
          ...prev,
          city: true,
        }));
      }

      if (!street) {
        return setError((prev) => ({
          ...prev,
          street: true,
        }));
      }

      if (!postCode) {
        return setError((prev) => ({
          ...prev,
          postCode: true,
        }));
      }

      if (!phone) {
        return setError((prev) => ({
          ...prev,
          phone: true,
        }));
      }
      setLoading(true);
      await updateProfile({
        name,
        dateOfBirth,
        country,
        city,
        street,
        postCode,
        phone,
        email,
      })
        .then(() => {
          NotificationManager.success(
            t("notification.success"),
            t("notification.title.success")
          );
        })
        .catch((err) => {
          NotificationManager.error(
            t("notification.error"),
            t("notification.title.error")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "20px",
          padding: "10px",
          marginTop: "20px",
          width: "100%",
          background:
            "linear-gradient(90deg, rgba(136,169,142,1) 0%, rgba(104,126,168,1) 100%)",
        }}
      >
        <Box
          sx={{
            padding: { xs: "20px", md: "0" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
          }}
        >
          <AdminPanelSettingsIcon sx={{ width: 80, height: 80 }} />
          <Box
            sx={{
              borderRadius: "15px",
              background: "#303030",
              border: "4px solid #2d5655",
              padding: "10px",
            }}
          >
            <Typography variant="body2">{t("profile.text_secure")}</Typography>
          </Box>
        </Box>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          >
            <Box
              sx={{
                padding: "10px",
                flex: 8,
              }}
            >
              <VerifyForm
                formValues={{
                  name,
                  dateOfBirth,
                  country,
                  city,
                  street,
                  postCode,
                  phone,
                  email,
                  error,
                }}
                actions={{
                  setCity,
                  setCountry,
                  setDateOfBirth,
                  setEmail,
                  setName,
                  setPhone,
                  setPostCode,
                  setStreet,
                  clearError,
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 10,
                padding: "10px 10px 20px 10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <ImageUploadForm />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <LoadingButton
                  sx={{ color: "#303030", backgroundColor: "#eee" }}
                  variant="contained"
                  loading={loading}
                  size="large"
                  color="inherit"
                  onClick={_updateCurrentUser}
                >
                  {t("send")}
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </form>
      </Box>
    );
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Box sx={{ width: "100%", maxWidth: "800px" }}>
      {action === "verify" ? (
        <Box>
          <IconButton
            aria-label="back"
            onClick={goBack}
            sx={{
              "&:hover": { background: "rgba(255, 255, 255, 0.1)" },
              transition: "0.3s",
              marginBottom: "10px",
            }}
          >
            <ArrowBackIcon sx={{ fontSize: "32px", color: "#eee" }} />
          </IconButton>
          <VerifyBlock />
          <VerifyFormBlock />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "stretch",
              gap: "10px",
              padding: "20px",
              borderRadius: "20px",
              background: "rgba(255, 255, 255, 0.1)",
            }}
          >
            {balance.map((item, index) => (
              <Box sx={{ flex: 1 }} key={index}>
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {item.title}
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
                  {item.balance.toFixed(2)}$
                </Typography>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "30px",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                flex: 10,
              }}
            >
              <Box
                sx={{
                  color: "white",
                  display: "flex",
                  flexDirection: "column",
                  padding: "20px",
                  borderRadius: "20px",
                  background: "rgba(255, 255, 255, 0.1)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{ color: "#4fa94f", fontWeight: "bolder" }}
                    >
                      {t("profile.status")}:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bolder" }}
                    >
                      {isVerified ? t("status_verified") : t("notVerified")}
                    </Typography>
                  </Box>
                  <Box>
                    <VerifiedUserIcon
                      sx={{ width: 48, height: 48, color: "#b18c1e" }}
                    />
                  </Box>
                </Box>
                {!isVerified && (
                  <Box
                    sx={{
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <LinkStyled
                      to={"/profile?action=verify"}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          color: "white",
                          margin: "0 auto",
                          textAlign: "center",
                        }}
                        variant="subtitle1"
                      >
                        {t("profile.verification")}
                      </Typography>
                    </LinkStyled>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                  borderRadius: "20px",
                  background: "rgba(255, 255, 255, 0.1)",
                }}
              >
                <Typography
                  sx={{ fontWeight: "bolder", textAlign: "center" }}
                  variant="h5"
                >
                  {t("profile.last_operations")}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    textAlign: "center",
                    margin: "10px 0",
                  }}
                  variant="body1"
                >
                  {t("profile.look_history")}
                </Typography>

                <img src="btclong.png" alt="" width="70%" />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                    paddingRight: "10px",
                  }}
                >
                  <Link
                    style={{
                      color: "white",
                      fontWeight: "bold",
                    }}
                    underline="hover"
                    to="/profile?tab=history"
                  >
                    {t("goto")}
                  </Link>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                flex: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                background: "rgba(255, 255, 255, 0.1)",
                borderRadius: "20px",
              }}
            >
              <Typography
                sx={{ fontWeight: "bolder", textAlign: "center" }}
                variant="h5"
              >
                {t("affiliateProgram")}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <StarIcon
                  sx={{
                    marginRight: "5px",
                    height: "40px",
                    width: "40px",
                    color: "orange",
                  }}
                />

                <Typography
                  variant="h4"
                  sx={{
                    color: "orange",
                    fontWeight: "bolder",
                  }}
                >
                  RANK {userProfile?.rank}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "20px" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
                    {userProfile?.referralList?.length || 0}
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                    {t("totalPartners")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <Typography variant="h4" sx={{ fontWeight: "bolder" }}>
                    {userProfile?.totalRefIncome || 0.0}$
                  </Typography>
                  <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                    {t("earned")}
                  </Typography>
                </Box>
              </Box>
              <img src="/pazzle.png" alt="graph" width="50%" />
              <Typography
                variant="body"
                sx={{
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#b18c1e",
                  fontSize: "20px",
                  marginTop: "20px",
                }}
              >
                {t("profile.upgrade_rank")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const LinkStyled = styled(Link)({
  display: "block",
  width: "100%",
  backgroundColor: "rgba(255,255,255, 0.4)",
  borderRadius: "10px",
  padding: "8px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",
  marginTop: "10px",

  "&:hover": {
    backgroundColor: "rgba(255,255,255, 0.2)",
    opacity: 0.8,
  },
});

export default ProfileSection;
