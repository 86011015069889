import {
  Box,
  Typography,
  Button,
  Container,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { setImageBody } from "../utils/dom";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import ScrollTop from "../components/ScrollToTop";
import { useAuth } from "../context/use-auth";
import { useNavigate } from "react-router-dom";
import SignUpModal from "../components/SignUpModal";
import { InvestTable } from "../components/invest";
import { Calculator } from "../components/calculator";
import { useState, useEffect } from "react";

function createData(
  rank,
  volume,
  level1,
  level2,
  level3,
  level4,
  level5,
  bonus
) {
  return { rank, volume, level1, level2, level3, level4, level5, bonus };
}

const rows = [
  createData("RANK 1", "0$", "5%", "1%", "1%", "0%", "0%", "0$"),
  createData("RANK 2", "5 000$", "7%", "2%", "1%", "0.5%", "0.5%", "250$"),
  createData("RANK 3", "20 000$", "8%", "2%", "1%", "1%", "1%", "750$"),
  createData("RANK 4", "50 000$", "10%", "2%", "1%", "1%", "1%", "1 500$"),
  createData("RANK 5", "100 000$", "12%", "2%", "1%", "1%", "1%", "2 500$"),
  createData("RANK 6", "250 000$", "15%", "3%", "2%", "1%", "1%", "7 500$"),
  createData("RANK 7", "500 000$", "18%", "3%", "2%", "2%", "1%", "12 500$"),
  createData("RANK 8", "1 000 000$", "20%", "4%", "3%", "2%", "1%", "25 000$"),
  createData("RANK 9", "2 500 000$", "22%", "5%", "3%", "2%", "2%", "75 000$"),
];

const Partners = () => {
  setImageBody("/partners-back.jpg");
  const [isOpenSignUpModal, openSignUpModal] = useState(false);
  const [calculateAmount, setCalculateAmount] = useState();

  const { t } = useTranslation();
  const { signUp, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const redirect = () => {
    if (!currentUser) {
      return openSignUpModal(true);
    }

    return navigate("/profile");
  };

  const onSignUp = (data) => {
    signUp(data).then(() => {
      openSignUpModal(false);
    });
  };

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <ScrollTop />

      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "auto",
          width: "100%",
          color: "white",
          paddingTop: "50px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: "1200px",
            padding: { xs: "10px", md: 0 },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              color: "#eee",
              maxWidth: "700px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bolder",
                color: "yellow",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              JUMPSY
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                textAlign: { xs: "center", md: "start" },
              }}
            >
              {t("partners.text_1")}
            </Typography>
          </Box>

          <Box
            sx={{
              margin: "100px auto",
              maxWidth: "900px",
            }}
          >
            <InvestTable t={t} type="partners" />

            <Box sx={{ marginTop: "30px" }}>
              <Calculator
                t={t}
                type="partners"
                setCalculateAmount={setCalculateAmount}
                calculateAmount={calculateAmount}
              />
            </Box>
          </Box>

          <Box
            sx={{
              margin: "100px auto",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              justifyContent: "center",
              maxWidth: "900px",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bolder",
                textAlign: { xs: "center", md: "start" },
                flex: 7,
              }}
            >
              {t("partners.text_7")}
            </Typography>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bolder", textAlign: "center", flex: 10 }}
            >
              {t("partners.text_8")} <span style={{ color: "yellow" }}>5%</span>{" "}
              {t("partners.text_9")}
            </Typography>
          </Box>
          <Box sx={{ margin: "100px auto" }}>
            <TableContainer component={Box}>
              <Table
                sx={{
                  minWidth: 650,
                  borderCollapse: "separate",
                  borderSpacing: "0 10px",
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow
                    sx={{
                      "& td, & th": {
                        border: 0,
                        color: "white",
                        fontWeight: "bold",
                        padding: "10px",
                        borderRight: "1px solid lightgray",

                        "&:last-child": {
                          borderRight: "none",
                        },
                      },
                      background: "rgba(255,255,255, 0.4)",
                      backdropFilter: "blur(10px)",
                    }}
                  >
                    <TableCell align="center">
                      {t("partners.text_10")}
                    </TableCell>
                    <TableCell align="center">
                      {t("partners.text_11")}
                    </TableCell>
                    <TableCell align="center">1 {t("level")}</TableCell>
                    <TableCell align="center">2 {t("level")}</TableCell>
                    <TableCell align="center">3 {t("level")}</TableCell>
                    <TableCell align="center">4 {t("level")}</TableCell>
                    <TableCell align="center">5 {t("level")}</TableCell>
                    <TableCell align="center">
                      {t("partners.text_13")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, idx) => (
                    <TableRow
                      key={`${idx + 1}-row}`}
                      sx={{
                        "& td, & th": {
                          border: 0,
                          color: "white",
                          fontWeight: "bold",
                          padding: "10px",
                        },
                        "&:hover": {
                          background: "rgba(255,255,255, 0.15)",
                        },
                        background: "rgba(255,255,255, 0.2)",
                        backdropFilter: "blur(10px)",
                      }}
                    >
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <StarIcon
                            sx={{
                              color: "orange",
                              fontSize: "18px",
                              margin: "0 5px",
                            }}
                          />
                          {row.rank}
                        </Box>
                      </TableCell>
                      <TableCell align="center">{row.volume}</TableCell>
                      <TableCell align="center">{row.level1}</TableCell>
                      <TableCell align="center">{row.level2}</TableCell>
                      <TableCell align="center">{row.level3}</TableCell>
                      <TableCell align="center">{row.level4}</TableCell>
                      <TableCell align="center">{row.level5}</TableCell>
                      <TableCell align="center">{row.bonus}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  background: "rgba(255,255,255, 0.2)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {t("partners.text_14")}{" "}
                  <span style={{ color: "orange", fontSize: "120%" }}>
                    {t("partners.text_15")}
                  </span>{" "}
                  {t("partners.text_16")}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  background: "rgba(255,255,255, 0.2)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {t("partners.text_17")}{" "}
                  <span style={{ color: "orange", fontSize: "120%" }}>
                    {t("partners.text_18")}
                  </span>{" "}
                  {t("partners.text_19")}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  background: "rgba(255,255,255, 0.2)",
                  backdropFilter: "blur(10px)",
                  borderRadius: "15px",
                  padding: "20px",
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
                  {t("partners.text_20")}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <StyledButton onClick={redirect}>
                {t("directions.text_12")}
              </StyledButton>
            </Box>
          </Box>
          <SignUpModal
            open={isOpenSignUpModal}
            handleClose={() => openSignUpModal(false)}
            onSignUp={onSignUp}
            t={t}
          />
          <Footer t={t} />
        </Container>
      </Box>
    </Box>
  );
};

const StyledButton = styled(Button)({
  background:
    "linear-gradient(90deg, rgba(255,173,0,1) 0%, rgba(255,78,86,1) 100%)",
  borderRadius: "10px",
  padding: "15px 40px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});

export default Partners;
