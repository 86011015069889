
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../config/firestore";

export const IMAGE_TYPES = {
  avatar: 'avatar',
  verification: 'verification'
};

const useUploadImage = (type = IMAGE_TYPES.avatar) => {
  const handleUpload = async (image, userId) => {
    if (image && userId) {
      const imageRef = ref(
        storage,
        `images/${userId}/${image.name}`
      );
  
      try {
        // Upload the image to Firebase Storage
        const snapshot = await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(snapshot.ref);

        let body = {};
        
        if (type === IMAGE_TYPES.avatar) {
          body = {
            avatar: imageUrl,
          };
        } else {
          body = {
            verificationImage: imageUrl,
          };
        }

        return body;
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    }
  };

  return {
    handleUpload,
  }
};

export default useUploadImage;