import React, { useEffect } from "react";
import { Container, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import homeBG1 from "../assets/home-bg-2.jpg";

import Section1 from "./home-section/section1";
import Section2 from "./home-section/section2";
import Footer from "../components/Footer";
import Section3 from "./home-section/section3";
import { setImageBody } from "../utils/dom";
import ScrollTop from "../components/ScrollToTop";

import ScrollAnimation from "react-animate-on-scroll";

import SignUpModal from "../components/SignUpModal";
import EducationModal from "../components/EducationModal";
import { useAuth } from "../context/use-auth";

const Home = () => {
  setImageBody(homeBG1);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isOpenSignUpModal, openSignUpModal] = React.useState(false);
  const [isOpenEducationModal, openEducationModal] = React.useState(false);
  const { signUp, currentUser } = useAuth();

  const onSignUp = (data) => {
    signUp(data).then(() => {
      openSignUpModal(false);
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "auto",
          width: "100%",
          color: "white",
          paddingTop: "50px",
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            maxWidth: "1200px",
            padding: { xs: "10px", md: 0 },
          }}
        >
          <ScrollTop />
          <Section1
            t={t}
            navigate={navigate}
            openSignUpModal={openSignUpModal}
            openEducationModal={openEducationModal}
            hasActionButtons={Boolean(currentUser)}
          />
          <ScrollAnimation animateIn="fadeIn" delay={100}>
            <Section2 t={t} navigate={navigate} />
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={100}>
            <Section3 t={t} navigate={navigate} />
          </ScrollAnimation>
          <Footer t={t} navigate={navigate} />
        </Container>
      </Box>
      <SignUpModal
        open={isOpenSignUpModal}
        handleClose={() => openSignUpModal(false)}
        onSignUp={onSignUp}
        t={t}
      />
      <EducationModal
        open={isOpenEducationModal}
        setOpen={openEducationModal}
      />
    </Box>
  );
};

export default Home;
