import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDCfhoI5U8w8rqx_9pTcITMBAh949GV8J4",
  authDomain: "jumpsy-b0eb4.firebaseapp.com",
  projectId: "jumpsy-b0eb4",
  storageBucket: "jumpsy-b0eb4.appspot.com",
  messagingSenderId: "27047164371",
  appId: "1:27047164371:web:cbd22c5f53ca1d86205726",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
