import React, { useState, useContext, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import moment from "moment";
import { NotificationManager } from "react-notifications";

import { useNavigate } from "react-router-dom";
import LoadingPage from "../pages/loading-page";
import { db } from "../config/firestore";
import { useTranslation } from "react-i18next";

export const errorTypes = {
  ["auth/weak-password"]: "weakPassword",
  ["auth/email-already-in-use"]: "emailAlreadyInUse",
};

const AuthContext = React.createContext({
  currentUser: null,
  loading: true,
  signUp: () => {},
  logout: () => {},
  signIn: () => {},
});

export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refID, setRefId] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const url = window.location.href;

    if (url.includes("ref")) {
      const userId = url.split("ref=")[1];
      setRefId(userId);
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
      }

      setLoading(false);
    });
  }, []);

  const createUser = async ({ email, password, phone }) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        setCurrentUser(user);

        localStorage.setItem("currentUser", JSON.stringify(user));
        const userRef = doc(db, "users", user.uid); // Create in DB reference to user by unique ID

        let submitBody = {
          uid: user.uid,
          email: user.email,
          createdAt: moment().format("DD.MM.YYYY"),
          isVerified: false,
          phone,
          avatar: "default",
          name: "",
          dateOfBirth: "",
          country: "",
          city: "",
          street: "",
          postCode: "",
          balance: 0,
          totalRefIncome: 0,
          totalRefBonus: 0,
          totalIncome: 0,
          totalInvestment: 0,
          investment: [],
          directions: [],
          transactions: [],
          rank: 1,
          password,
        };

        if (refID) {
          submitBody = {
            ...submitBody,
            referredBy: refID,
          };

          const refUserRef = doc(db, "users", refID);
          await updateDoc(refUserRef, {
            referralList: arrayUnion({
              email: user.email,
              uid: user.uid,
              date: moment().format("DD.MM.YYYY"),
              deposit: "0",
              refIncome: "0",
              refBonus: "0",
            }),
          });
        }

        // Set user data in DB
        await setDoc(userRef, submitBody);

        return user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = errorTypes[errorCode] || "loginError";
        NotificationManager.error(
          t(errorMessage),
          t("notification.title.error")
        );
      });
  };

  const signUp = async (data) => {
    setLoading(true);
    await createUser(data)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          navigate("/profile");
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const signIn = async ({ email, password }) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userRef = doc(db, "users", user.uid);
        setCurrentUser(user);
        localStorage.setItem("currentUser", JSON.stringify(user));

        await updateDoc(userRef, { password });

        setTimeout(() => {
          navigate("/profile");
        }, 1000);

        return user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = errorTypes[errorCode] || "loginError";
        NotificationManager.error(
          t(errorMessage),
          t("notification.title.error")
        );
      });
  };

  const logout = async () => {
    await signOut(auth);
    localStorage.removeItem("currentUser");
    localStorage.removeItem("isKeepSession");
    setCurrentUser(null);
    navigate("/");
  };

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        loading,
        signUp,
        logout,
        signIn,
      }}
    >
      {loading ? <LoadingPage /> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
