import { Box, Typography, Button, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../config/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import { NotificationManager } from "react-notifications";
import { useAuth } from "../../context/use-auth";

import InputAdornment from "@mui/material/InputAdornment";
import TextsmsIcon from "@mui/icons-material/Textsms";
import MessageIcon from "@mui/icons-material/Message";
import { useTranslation } from "react-i18next";

const HelpSection = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const resetForm = () => {
    setTitle("");
    setDescription("");
  };

  const submitFeedback = async () => {
    setLoading(true);
    try {
      await addDoc(collection(db, "feedbacks"), {
        title,
        description,
        userId: currentUser.uid,
        createdAt: new Date(),
      });
      resetForm();
      NotificationManager.success(
        t("notification.success"),
        t("notification.title.success")
      );
    } catch (error) {
      console.error("Error submitting feedback:", error);
      NotificationManager.error(
        t("notification.error"),
        t("notification.title.error")
      );
    }

    setLoading(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "800px",
        gap: "10px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bolder",
          borderBottom: "2px solid yellow",
          maxWidth: "max-content",
          padding: "0 10px",
          marginBottom: "20px",
        }}
      >
        {t("profile.menu.help")}
      </Typography>
      <Box
        sx={{
          width: { xs: "100%", md: "400px" },
          padding: "20px",
          borderRadius: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          background:
            "linear-gradient(90deg, rgba(205,255,216,1) 0%, rgba(148,185,255,1) 100%)",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
          {t("profile.help.create_ticket")}
        </Typography>
        <StyledInput
          label={t("profile.help.theme")}
          placeholder={t("profile.help.theme")}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TextsmsIcon />
              </InputAdornment>
            ),
          }}
        />
        <StyledInput
          multiline
          rows={8}
          label={t("profile.help.your_question")}
          placeholder={t("profile.help.your_question")}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  height: "100%",
                  marginBottom: "auto",
                }}
              >
                <MessageIcon />
              </InputAdornment>
            ),
          }}
        />
        <StyledButton loading={loading} onClick={submitFeedback}>
          {t("profile.help.create_ticket")}
        </StyledButton>
      </Box>
      <Box
        sx={{
          padding: "40px 40px 20px 40px",
          borderRadius: "20px",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          background:
            "linear-gradient(90deg, rgba(131,132,131,1) 0%, rgba(148,185,255,1) 100%)",
        }}
      >
        <Box
          sx={{
            width: "70%",
            minWidth: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "bolder", color: "#303030", textAlign: "center" }}
          >
            {t("profile.help.text1")}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              marginTop: "20px",
              fontWeight: "bolder",
              color: "white",
              textAlign: "center",
            }}
          >
            {t("profile.help.text2")}
          </Typography>

          <StyledButtonFaq onClick={() => navigate("/faq")}>
            {t("profile.help.text3")}
          </StyledButtonFaq>
        </Box>
      </Box>
    </Box>
  );
};

const StyledButtonFaq = styled(Button)({
  borderRadius: "10px",
  backgroundColor: "#045782",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",
  margin: "20px auto 0 auto",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});
const StyledButton = styled(LoadingButton)({
  borderRadius: "15px",
  backgroundColor: "#303030",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});

const StyledInput = styled(TextField)({
  // outline: "3px solid black",
  "& fieldset": {
    borderWidth: 3,
    borderColor: "#606060",
    borderRadius: "15px",
  },
});

export default HelpSection;
