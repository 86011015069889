import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TelegramIcon from "@mui/icons-material/Telegram";
import EmailIcon from "@mui/icons-material/Email";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { useLanguage } from "../context/use-language";
import moment from "moment";

import presentPDF from "../assets/eng.pdf";
import certPDF from "../assets/certification.pdf";

import privacyFileEN from "../assets/policy/policy-en.rtf";
import privacyFileCN from "../assets/policy/policy-cn.rtf";
import privacyFileRU from "../assets/policy/policy-ru.rtf";
import privacyFileDE from "../assets/policy/policy-de.rtf";
import privacyFileFR from "../assets/policy/policy-fr.rtf";

const POLICY = {
  en: privacyFileEN,
  ru: privacyFileRU,
  cn: privacyFileCN,
  fr: privacyFileFR,
  de: privacyFileDE,
};

const useStyles = makeStyles({
  link: {
    color: "#89CFF0",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
    },
  },
});

const social = {
  backgroundColor: "#2AABEE",
  borderRadius: "50%",
  width: "50px",
  height: "50px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 10px",
};

const textLinkStyle = {
  color: "#fff",
  fontWeight: 600,
  fontSize: 14,
  whiteSpace: "initial",
  cursor: "pointer",
};

const Footer = ({ t }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { language } = useLanguage();

  const openPresentation = (type = "") => {
    let pdf = certPDF;

    if (type === "presentation") {
      pdf = presentPDF;
    }

    const newWindow = window.open(pdf, "_blank");

    if (newWindow) {
      newWindow.focus();
      newWindow.onload = () => newWindow.print();
    }
  };

  const navigate = useNavigate();

  const openLink = (link) => {
    window.open(link, "_blank");
  };

  const onClickPrivacy = () => {
    const privacyFile = POLICY[language];
    window.open(privacyFile, "_blank");
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
          flexDirection: isMobile ? "column" : "row",
        }}
        padding={{ sm: "0 20px", md: "0 20px" }}
      >
        <Box>
          <Typography variant="h6" sx={{ color: "#fff", fontWeight: 600 }}>
            {t("useful_links")}
          </Typography>
          <Box>
            <Typography
              sx={textLinkStyle}
              onClick={() => navigate("/about-us")}
            >
              {t("about_us")}
            </Typography>
            <Typography sx={textLinkStyle} onClick={() => navigate("/faq")}>
              {t("faq")}
            </Typography>
            <Typography
              sx={{ ...textLinkStyle, cursor: "pointer" }}
              onClick={() => openPresentation("presentation")}
            >
              {t("presentation")}
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => navigate("/directions")}
            >
              {t("directions")}
            </Typography>
            <Typography onClick={onClickPrivacy} sx={textLinkStyle}>
              {t("privacy_policy")}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ color: "#fff", fontWeight: 600 }}>
            {t("instruments")}
          </Typography>
          <Box>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://www.santos.com/")}
            >
              Santos Limited
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://www.sundrivesolar.com")}
            >
              SunDrive
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://www.riotinto.com/")}
            >
              Rio Tinto Group
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://coinsbit.io/")}
            >
              Coinsbit
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://www.coinbase.com/")}
            >
              Coinbase
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://www.kucoin.com/")}
            >
              Kucoin
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://www.kraken.com/")}
            >
              Kraken
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://garantex.org/")}
            >
              Garantex
            </Typography>
            <Typography
              sx={textLinkStyle}
              onClick={() => openLink("https://upbit.com/")}
            >
              Upbit
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" sx={{ color: "#fff", fontWeight: 600 }}>
            {t("contacts")}
          </Typography>
          <Box>
            <Typography
              className={classes.link}
              sx={{ ...textLinkStyle, cursor: "pointer" }}
              onClick={() => openPresentation("certification")}
            >
              Australian Securities & Investments Commission
            </Typography>
            <Typography sx={textLinkStyle}>
              Suite 3, 132 Burwood Road, New South Wales
            </Typography>
            <Typography sx={textLinkStyle}>Australia, 2134</Typography>
            <Typography sx={textLinkStyle}>
              helpers.jumpsy.io@gmail.com
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
            }}
          >
            <Box
              sx={social}
              onClick={() => openLink("https://t.me/jumpsy_support")}
            >
              <TelegramIcon />
            </Box>
            <Box
              sx={social}
              onClick={() =>
                window.open("mailto:helpers.jumpsy.io@gmail.com", "_blank")
              }
            >
              <EmailIcon />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px 0",
        }}
      >
        <Typography sx={{ color: "#fff", fontSize: 14, fontWeight: 600 }}>
          "Jumpsy Financial Company" all rights reserved © {moment().year()}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
