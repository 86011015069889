import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { MenuItem, Select } from "@mui/material";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import NavLink from "./NavLink";
import MainLogo from "./MainLogo";
import MainButton from "./MainButton";

import { useLanguage, LANGUAGES } from "../context/use-language";

const drawerWidth = 240;
export const authItems = ["language", "profile", "home", "logout"];
export const unAuthItems = [
  {
    name: "home",
    routePath: "/",
  },
  {
    name: "about_us",
    routePath: "/about-us",
  },
  {
    name: "faq",
    routePath: "/faq",
  },
  {
    name: "directions",
    routePath: "/directions",
  },
  {
    name: "partners",
    routePath: "/partners",
  },
];

export const unAthActionItems = ["language", "sign-in", "sign-up"];
export const authActionItems = ["language", "profile", "home"];

const StyledSelect = styled(Select)({
  outline: "none",
  backgroundColor: "transparent",
  border: "2px solid yellow",
  borderWidth: "2px",
  color: "white",
  borderRadius: "8px",
  flex: 1,
  width: 150,
  marginTop: 10,

  "& .MuiSelect-select": {
    padding: "10px",
    fontWeight: "bold",
    fontSize: "15px",
  },

  "& label.Mui-focused, & .MuiSvgIcon-root": {
    color: "white",
  },

  "& fieldset": {
    border: "none",
  },
});

const DrawerAppBar = ({
  handleDrawerToggle,
  mobileOpen,
  window,
  isAuth,
  authActions,
  unAuthActions,
  t,
}) => {
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const _handleDrawerToggle = () => {
    handleDrawerToggle((prevState) => !prevState);
  };

  const { language, changeLanguage } = useLanguage();

  const navItems = useMemo(() => {
    if (isAuth) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: "20px",
          }}
        >
          <MainButton text={t('profile')} onClick={authActions.goProfile} />
          <MainButton text={t('logout')} onClick={authActions.logout} />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          gap: "20px",
        }}
      >
        {unAuthItems.map((item) => (
          <NavLink key={item.name} routePath={item.routePath}>
            {t(item.name)}
          </NavLink>
        ))}
        <MainButton
          text={t("button.sign_in")}
          onClick={() => unAuthActions.openSignInModal(true)}
        />
        <MainButton
          text={t("button.sign_up")}
          onClick={() => unAuthActions.openSignUpModal(true)}
        />
      </Box>
    );
  }, []);

  return (
    <nav>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none", height: "100%" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        <Box
          onClick={_handleDrawerToggle}
          sx={{ textAlign: "center", backgroundColor: "#000", height: "100%" }}
        >
          <NavLink routePath="/">
            <MainLogo />
          </NavLink>
          <Divider />
          <List>{navItems}</List>
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={language}
            onChange={(e) => {
              changeLanguage(e.target.value);
            }}
          >
            {LANGUAGES.map((lang, idx) => (
              <MenuItem
                key={`${lang.code}-${idx + 1}}`}
                value={lang.code}
                sx={{ textTransform: "uppercase" }}
              >
                {lang.flag} {lang.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </Box>
      </Drawer>
    </nav>
  );
};

export default DrawerAppBar;
