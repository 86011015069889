import React, { useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import Footer from "../components/Footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setImageBody } from "../utils/dom";
import faqBG from "../assets/faq-bg.jpg";
import faqSearch from "../assets/faq-search-image.png";

const faqList = (t) =>
  [...Array(10).keys()].map((i) => ({
    question: t(`faq.question.${i + 1}`),
    answer: t(`faq.answer.${i + 1}`),
  }));

const overlayStyles = {
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1,
  },
};

const FAQ = () => {
  setImageBody(faqBG);
  const { t } = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box sx={{ paddingTop: "64px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
          ...overlayStyles,
          width: "100%",
        }}
      >
        <Box
          sx={{
            maxWidth: "1080px",
            margin: "0 auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            zIndex: 2,
          }}
          flexDirection={{ xs: "column", md: "row" }}
          padding={{ xs: "20px", md: "0" }}
          paddingTop={{ xs: "37px", md: "64px" }}
        >
          <Box>
            {faqList(t).map((faq, index) => (
              <Accordion
                key={index}
                sx={{
                  borderRadius: "10px",
                  boxShadow: "none",
                  marginBottom: "20px",
                  background: "transparent",
                }}
              >
                <AccordionSummary
                  sx={{
                    padding: "5px",
                    border: "1px solid #fff !important",
                    borderRadius: "10px",
                    padding: "0 20px",
                    position: "relative",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                    }}
                    variant="subtitle1"
                  >
                    {faq.question}
                  </Typography>
                  <Box
                    sx={{
                      position: "absolute",
                      right: "20px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    <ExpandMoreIcon
                      sx={{
                        color: "#fff",
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: "0 20px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontWeight: 600,
                      marginTop: "10px",
                    }}
                    variant="subtitle1"
                  >
                    {faq.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
            }}
          >
            <Box
              sx={{
                backgroundImage: `url(${faqSearch})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "175px",
                height: "175px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FAQ;
