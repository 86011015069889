import { Box, Typography, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { GradientListButton } from "./balance-section";
import { useMemo, useState } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { arrayUnion } from "firebase/firestore";
import { NotificationManager } from "react-notifications";

import { useProfile } from "../../context/use-profile";
import { iconList } from './balance-section';

const DepositSection = ({ balance }) => {
  const [hash, setHash] = useState("");

  const location = useLocation();
  const { t } = useTranslation();
  const { updateProfile, refetchUser, paymentList } = useProfile();
  const searchParams = new URLSearchParams(location.search);
  const amount = searchParams.get("amount");
  const action = searchParams.get("action");
  const selectedPaySystem = searchParams.get("paySystem");
  const account = searchParams.get("account");
  const investType = searchParams.get("investType");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const paySystem = useMemo(() => {
    const findSystem = paymentList.find(
      (item) => item.value === selectedPaySystem
    );
    return findSystem || {};
  }, [selectedPaySystem]);

  const onSendHash = async () => {
    if (!hash) {
      return;
    }

    setLoading(true);

    const data = {
      hashNumber: hash,
      dateCreated: moment().format(),
      status: "pending",
      action,
      amount,
      receiverAccount: account,
      investType: investType || "",
    };

    if (investType !== "null") {
      const investment = arrayUnion({
        investType,
        amount,
        income: 0,
        dateCreated: moment().format(),
        status: "in process",
      });

      updateProfile({
        transactions: arrayUnion(data),
        investment,
      })
        .then(() => {
          refetchUser();
          setLoading(false);
          NotificationManager.success(
            t("notification.success"),
            t("notification.title.success")
          );
          setHash("");
        })
        .catch((error) => {
          NotificationManager.error(
            t("notification.error"),
            t("notification.title.error")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      updateProfile({
        transactions: arrayUnion(data),
      })
        .then(() => {
          refetchUser();
          setLoading(false);
          NotificationManager.success(
            t("notification.success"),
            t("notification.title.success")
          );
          setHash("");
        })
        .catch((error) => {
          NotificationManager.error(
            t("notification.error"),
            t("notification.title.error")
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "700px",
        gap: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px",
          border: "3px solid #435652",
          borderRadius: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              padding: "10px 70px",
              border: "3px solid #435652",
              borderRadius: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bolder", textAlign: "center" }}
            >
              {t("profile.deposit.deposit_balance")}
            </Typography>
          </Box>
          <Box sx={{ width: { xs: "100%", md: "200px" } }}>
            <GradientListButton
              disabled
              sx={{
                display: "flex",
                paddingLeft: 0,
                justifyContent: "space-between",
                "&.Mui-disabled": { opacity: "1" },
              }}
            >
              <img src={iconList[paySystem.value]} alt={paySystem.label} width="35px" />
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                {paySystem.label}
              </Typography>
            </GradientListButton>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            width: "100%",
          }}
        >
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.deposit.text1", { system: paySystem.label })}
          </Typography>
          <Box
            sx={{
              margin: "20px 0",
              display: "flex",
              padding: "5px 20px",
              borderRadius: "10px",
              gap: "10px",
              alignItems: "center",
              background: "rgba(255,255,255,0.15)",
              width: { xs: "100%", md: "max-content" },
              border: "1px solid #404040",
              transition: "0.3s",
              cursor: "pointer",
              "&:hover": {
                background: "rgba(255,255,255,0.2)",
              },
            }}
            onClick={() => {
              navigator.clipboard.writeText(account);
            }}
          >
            <ContentCopyIcon sx={{ width: 30, height: 30 }} />
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", wordBreak: "break-all" }}
            >
              {account}
            </Typography>
          </Box>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.deposit.text2")}
          </Typography>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.deposit.text3")}
          </Typography>
          <Typography variant="body" sx={{ fontWeight: "bold" }}>
            {t("profile.deposit.text4")}
          </Typography>
          <Box
            sx={{
              marginTop: "-17px",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",
              gap: "20px",
            }}
          >
            <StyledInput
              sx={{ flex: 1, width: { xs: "100%", md: "auto" } }}
              placeholder={t("profile.deposit.address_example")}
              value={hash}
              onChange={(e) => setHash(e.target.value)}
            />
            <StyledButton
              loading={loading}
              sx={{
                flex: { xs: 1, md: "0 0 200px" },
                width: { xs: "100%", md: "auto" },
                color: "white",
              }}
              onClick={onSendHash}
            >
              {t("send")}
            </StyledButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "20px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            position: "relative",
            backgroundImage: 'url("/crypto.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: "1/1",
            flex: 1,
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              right: 0,
              bottom: 0,
              padding: "20px",
            }}
          >
            <Box
              sx={{
                background: "rgba(0,0,0,0.75)",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: "bolder", lineHeight: 1, fontSize: "28px" }}
              >
                {t("profile.deposit.text5")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            position: "relative",
            backgroundImage: 'url("/support.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
            aspectRatio: "1/1",
            flex: 1,
            borderRadius: "20px",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              right: 0,
              top: 0,
              padding: "20px",
            }}
          >
            <Box
              sx={{
                background: "rgba(0,0,0,0.75)",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "bolder",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {t("profile.deposit.text6")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              right: 0,
              bottom: 0,
              padding: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => navigate("/profile?tab=help")}
              sx={{
                background: "rgba(0,0,0,1)",
                padding: "10px 30px",
                borderRadius: "20px",
                color: "white",
                fontWeight: "bolder",
                fontSize: "24px",
                textTransform: "capitalize",
                border: "1px solid gray",

                "&:hover": {
                  background: "rgba(0,0,0,0.8)",
                },
              }}
            >
              {t("write")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const StyledButton = styled(LoadingButton)({
  borderRadius: "15px",
  backgroundColor: "#306c76",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});

const StyledInput = styled(TextField)({
  outline: "none",
  backgroundColor: "transparent",
  border: "3px solid #435652",
  borderRadius: "20px",

  "& label.Mui-focused": {
    color: "black",
  },
  "& label.MuiFormLabel-filled, & label.Mui-focused": {
    top: "0px",
    fontWeight: "bold",
  },
  "& label": {
    top: "-4px",
    fontWeight: "bold",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#708d7f",
    },
  },
  "& input": {
    fontSize: "18px",
    fontWeight: "bold",
    color: "white",
    padding: "5px 30px",
    height: "34px",
  },
  "& fieldset": {
    border: "none",
  },
});

export default DepositSection;
