import React from "react";
import { LoadingButton } from "@mui/lab";

const AdditionalButton = ({ text, onClick, loading }) => {
  return (
    <LoadingButton
      onClick={onClick}
      loading={loading}
      sx={{
        height: "50px",
        color: "#000",
        backgroundColor: "#ffe599",
        borderWidth: "2px",
        borderRadius: "8px",
        fontSize: "15px",
        fontWeight: "700",
        textTransform: "none",
        marginRight: "20px",
        width: "100%",
      }}
    >
      {text}
    </LoadingButton>
  );
};

export default AdditionalButton;
