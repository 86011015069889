import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import moment from "moment";

const getProperActionType = (type = "") => {
  if (type === "deposit") {
    return "depositType";
  }

  if (type === "withdraw") {
    return "withdrawType";
  }

  return type;
};

const HistorySection = ({ transactions }) => {
  const { t } = useTranslation();
  function createData(name, status, amount, date, type) {
    return { name, status, amount, date, type };
  }

  const rows = useMemo(() => {
    let rowList = [];

    if (transactions.length) {
      rowList = transactions.map((item) => {
        return createData(
          getProperActionType(item.action),
          item.status,
          item.amount,
          item.dateCreated,
          getProperActionType(item.action)
        );
      });
    }

    return rowList.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "800px",
        gap: "10px",
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bolder",
          borderBottom: "2px solid yellow",
          maxWidth: "max-content",
          padding: "0 10px",
          marginBottom: "20px",
        }}
      >
        {t("profile.menu.history")}
      </Typography>
      <Box>
        <StyledButton>
          {t("allOperations")}{" "}
          <ArrowForwardIcon
            sx={{
              marginLeft: "10px",
            }}
            width={30}
          />
        </StyledButton>
      </Box>
      <Box
        sx={{
          overflowX: "auto",
        }}
      >
        <TableContainer component={Box}>
          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "separate",
              borderSpacing: "0 10px",
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                sx={{
                  "& td, & th": {
                    border: 0,
                    color: "white",
                    fontWeight: "bold",
                    padding: "10px",
                    borderRight: "1px solid lightgray",

                    "&:last-child": {
                      borderRight: "none",
                    },
                  },
                  background:
                    "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,165,170,1) 100%);",
                }}
              >
                <TableCell align="center">{t("operations")}</TableCell>
                <TableCell align="center">{t("profile.status")}</TableCell>
                <TableCell align="center">{t("sum")}</TableCell>
                <TableCell align="center">{t("date")}</TableCell>
                <TableCell align="center">{t("comment")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    "& td, & th": {
                      border: 0,
                      color: "white",
                      fontWeight: "bold",
                      padding: "10px",
                    },
                    background:
                      "linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,165,170,1) 100%);",
                  }}
                >
                  <TableCell component="th" scope="row" align="center">
                    {t(row.name)}
                  </TableCell>
                  <TableCell align="center">{row.status}</TableCell>
                  <TableCell align="center">{row.amount}$</TableCell>
                  <TableCell align="center">
                    {moment(row.date).format("MM.DD.YYYY")}
                  </TableCell>
                  <TableCell align="center">{t(row.name)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

const StyledButton = styled(Button)({
  borderRadius: "15px",
  backgroundColor: "#306c76",
  padding: "10px 30px",
  color: "white",
  fontWeight: "bolder",
  transition: "0.3s",

  "&:hover": {
    opacity: 0.8,
    backgroundColor: "#306c76",
  },
});

export default HistorySection;
