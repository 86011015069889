export const isEmail = (email) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
};

export const onInputNumber = (e, set) => {
  const val = e.target.value
  const lengthAfterComma = val.toString().includes('.') ? (val.toString().split('.').pop().length) : (0)
  if (parseInt(val).toString().length > 9) {
    e.preventDefault();
    return
  }

  if (e.nativeEvent.data && lengthAfterComma > 2) {
    set(parseFloat(val).toFixed(2))
    return
  }

  set(val)
}