import { Box, Typography } from "@mui/material";

import { useMemo, useState } from "react";
import { InvestTable } from "../../components/invest";
import { Calculator } from "../../components/calculator";

const InvestSection = ({ t, userProfile }) => {
  const [calculateAmount, setCalculateAmount] = useState();

  const balanceQuotes = useMemo(() => {
    return [
      { title: t("availableBalance"), balance: userProfile?.balance || 0.0 },
      { title: t("totalIncome"), balance: userProfile?.totalIncome || 0.0 },
      {
        title: t("total_invest"),
        balance: userProfile?.totalInvestment || 0.0,
      },
    ];
  }, [t, userProfile]);

  return (
    <Box sx={{ width: "100%", maxWidth: { md: "800px" }, flexGrow: 1 }}>
      <InvestTable
        calculateAmount={calculateAmount}
        balance={userProfile?.balance || 0}
        t={t}
      />

      <Box sx={{ marginTop: "30px" }}>
        <Calculator
          calculateAmount={calculateAmount}
          setCalculateAmount={setCalculateAmount}
          t={t}
          type="profile"
        />
      </Box>

      <Box
        sx={{
          marginTop: "30px",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "stretch",
          gap: "10px",
          padding: "20px",
          border: "3px solid #07bb6a",
          borderRadius: "20px",
        }}
      >
        {balanceQuotes.map((item) => (
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: "bolder" }}>
              {item.title}
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: "bolder" }}>
              {item.balance.toFixed(2)}$
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default InvestSection;
