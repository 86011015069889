import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";

import { AuthProvider } from "./context/use-auth";
import { LanguageProvider } from "./context/use-language";
import NavigationBar from "./components/NavigationBar";
import { NotificationContainer } from "react-notifications";
import "./veriables.css";
import "./config/firestore";

import theme from "./theme";
import { ProfileProvider } from "./context/use-profile";
import "react-notifications/lib/notifications.css";
import "./font.css";

function App() {
  document.getElementById("root").style.flex = 1;
  document.getElementById("root").style.display = "flex";
  document.getElementById("root").style.flexDirection = "column";

  return (
    <LanguageProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NotificationContainer />
        <Router>
          <AuthProvider>
            <ProfileProvider>
              <NavigationBar />
            </ProfileProvider>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </LanguageProvider>
  );
}

export default App;
