import axios from "axios";

const API =
  "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin,ethereum,zcash,xrp,dash,litecoin,dogecoin,bitcoin-cash";

export const onFetchCryptoData = async () => {
  try {
    const result = await axios.get(API, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return result.data;
  } catch (error) {
    return [];
  }
};
